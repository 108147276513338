.button,
.btn {
    display: inline-flex;
    align-items: center;
    padding: $width-gap/2;
    text-decoration: $style-button;
    background: $color-primary;
    color: #fff;
    font-family: $base-headline-font;
    letter-spacing: 0.05em;

    .english {
        display: block;
        font-family: $base-font;
        opacity: 0.75;
        font-size: 0.875em;
        margin-left: 0.5em;
    }

    &.baseline { align-items: baseline; }

    &.active { background: $color-tertiary; }

    &.disabled { opacity: 0.5; }


    &.outline {
        background: none;
        box-shadow: inset 0px 0px 0px 2px $color-primary;
        color: $color-tertiary;

        &:hover { color: #fff; }
    }

    &.icon-button { line-height: 1; }

    &.with-icon .icon { margin-left: 0.5rem; }


    &:hover { background: darken($color-primary, 2.5%); }

    &.action {
        background: $color-action;
        color: #fff;

        &:hover { background: darken($color-action, 2.5%); }
    }

    &.secondary {
        background: $color-secondary;
        color: #fff;

        &:hover { background: darken($color-secondary, 2.5%); }
    }

    @media all and (min-width: 641px) {
        .english {
            display: inline;
            width: auto;
            margin-left: 0.5em;
        }
    }
}

.button__row,
.btn__row {
    margin-top: $width-gap;
}

.button__multilingual {
    font-family: $base-headline-font;
    display: inline-flex;
    flex-flow: column nowrap;
    text-transform: uppercase;
    align-items: flex-start;
    font-size: 1.125rem;
    color: #fff;
    appearance: none;
    background: none;
    padding: 0;
    margin: 0;
    border: none;
    cursor: pointer;

    span {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        background: $color-primary;
        padding: $width-gap/2 $width-gap $width-gap/2 $width-gap;

        .icon {
            vertical-align: top;
            margin-left: 0.25em;
        }
    }

    .english {
        font-family: $base-font;
        text-transform: none;
        font-size: 0.875em;
        margin-top: -$width-gap*0.75;
    }

    &:hover span {
        background: darken($color-primary, 2.5%);
    }
}

.page__buttons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;

    @media all and (min-width: 641px) {
        padding-right: $width-gap;

    }

    @media all and (min-width: 1221px) {
        padding-right: 0;

        .page__content-holder + & {
            padding-right: $width-gap;
        }
    }
}
