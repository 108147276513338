.glitter_page_blocktype_panel {
    .panel__image {
        display: none;
        min-height: 25rem;
    }

    .panel__welsh,
    .panel__english {
        padding: $width-gap*2;
        background: #fff;
        color: $color-text-welsh;
    }

    .panel__english {
        color: $color-text-english;
    }

    .button {
        text-transform: uppercase;
    }

    @media all and (max-width: 640px) {
        .panel__english {
            position: relative;

            &:before {
                content: '';
                display: block;
                position: absolute;
                left: $width-gap*2;
                right: $width-gap*2;
                top: 0;
                border-top: 1px dashed $color-line;
            }
        }

    }

    @media all and (min-width: 641px) {
        > * {
            width: 50%;
        }
    }

    @media all and (min-width: 641px) {
        display: flex;
        flex-flow: row nowrap;

        &.align-left,
        &.align-right {
            .panel__english {
                position: relative;

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    left: -1px;
                    top: $width-gap;
                    bottom: $width-gap;
                    border-right: 1px dashed $color-line;
                }
            }
        }
    }

    @media all and (min-width: 641px) and (max-width: 960px) {
        &.align-center {
            .panel__english {
                position: relative;

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    left: -1px;
                    top: $width-gap;
                    bottom: $width-gap;
                    border-right: 1px dashed $color-line;
                }
            }
        }
    }

    @media all and (min-width: 1361px) {
        > * {
            width: calc(100% / 3);
            flex-shrink: 0;
        }

        .panel__image { display: block; }

        &.align-center {

            .panel__image { order: 1; }
            .panel__english { order: 2; }
        }
        &.align-right {

            .panel__image { order: 2; }
        }
    }

    body.home &,
    .page__billboard & {
        @media all and (min-width: 961px) and (max-width: 1360px) {
            > * {
                width: calc(100% / 3);
                flex-shrink: 0;
            }

            .panel__image { display: block; }

            &.align-center {

                .panel__image { order: 1; }
                .panel__english { order: 2; }
            }
            &.align-right {

                .panel__image { order: 2; }
            }
        }
    }
}
