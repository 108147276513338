html,
body {
    width: 100%;
    overflow-x: hidden;
}

.page__holder {
    display: flex;
    flex-flow: row nowrap;
    transition: left 0.5s cubic-bezier(0.23, 1, 0.32, 1);
    will-change: left;
    position: relative;
    left: -100%;
    min-height: 100vh;
    max-height: 100vh;

    @media all and (min-width: 641px) {
        left: -25rem;
    }

    .menu__open & { left: 0; }
}

.page__content {
    width: 100%;
    min-height: 100%;
    flex-shrink: 0;
    background: $color-background;
    position: relative;
    overflow: auto;
}

.page__container {
    max-width: 75rem;
    margin: auto;

    #glitter_column_billboard {
        .glitter_page_block:last-of-type {
            margin-bottom: $width-gap;
        }

        .glitter_page_blocktype_imageblock,
        .glitter_page_blocktype_carouselblock {

            &:last-of-type { margin-bottom: 0; }
        }
    }
}

body:not(.home) {
    .page__content-wrapper > main {
        max-width: 75rem;
        margin: auto;
    }
}

.page__content-wrapper {
    min-height: 100%;

    @media all and (min-width: 641px) {
        position: relative;
        padding-left: $width-gap;

        &:before {
            content: '';
            display: block;
            background: $color-tertiary;
            position: absolute;
            top: 0;
            left: 0;
            min-height: 100%;
            z-index: 5;
            width: 1rem;
        }
    }
}

.page__billboard {

    .glitter_page_block:last-of-type {
        margin-bottom: $width-gap;
    }
}

.page__content-holder {

    @media all and (min-width: 641px) {
        padding-right: $width-gap;

        body.home & {
            padding-right: 0;
        }
    }

    @media all and (min-width: 961px) {
        display: flex;
        flex-flow: row nowrap;
    }
}

.page__content-sidebar {
    margin-bottom: $width-gap;
    display: none;

    @media all and (min-width: 961px) {
        display: block;
    }
}

.page__split-welsh,
.page__split-english {
    padding: $width-gap*1.5;
    position: relative;

    .default &,
    .single-col & {
        padding-top: 4rem;

        .page__split-link {
            position: absolute;
            top: -1px; left: 0;
        }
    }
}

.page__split-welsh { color: $color-text-welsh; }
.page__split-english { color: $color-text-english; }

.page__split-english {
    padding-top: 4rem;

    .page__split-link {
        position: absolute;
        top: -1px; left: 0;

        + * { margin-top: 0; }
    }

    @media all and (max-width: 640px) {
        border-top: 1px dashed $color-line;
        margin-top: $width-gap;
    }
}

.page__split {
    background: #fff;

    @media all and (min-width: 641px) {
        display: flex;
        flex-flow: row nowrap;
        padding: $width-gap;

        .page__split-welsh,
        .page__split-english {
            width: 50%;
            padding-top: $width-gap*1.5;
        }

        .page__split-english:before {
            content: '';
            display: block;
            position: absolute;
            top: $width-gap;
            bottom: $width-gap;
            left: 0;
            border-left: 1px dashed $color-line;
        }
    }
}

.page__split-link {
    text-transform: uppercase;
    font-size: 1rem;

    @media all and (min-width: 641px) {
        display: none;
    }
}

@media all and (min-width: 961px) {
    .page__content-sidebar {
        width: 18.5rem;
        flex-shrink: 0;
        margin: 0 $width-gap 0 0;
    }

    .page__content-body {
        width: 100%;
    }
}

.sidebar__nav {
    background: #fff;
    padding: $width-gap;

    .sidebar__nav-title {
        margin-bottom: $width-gap;
        color: $color-text-welsh;

        > * { margin: 0; }

        p {
            color: $color-text-english;
            font-size: 1.125rem;
        }
    }

    + .sidebar__nav {
        margin-top: $width-gap*2;
    }
}
