.document__details {
    display: flex;
    width: 100%;
    flex-flow: column nowrap;
    margin-bottom: $width-gap;

    @media all and (min-width: 768px) {
        flex-flow: row nowrap;

        .document__meta,
        .document__image {
            width: calc(50% - #{$width-gap/2)});
            flex-grow: 1;
        }

        .document__image + .document__meta {
            margin-left: $width-gap;
        }
    }
}

.document__image {
    min-height: 10rem;
}

.document__social {
    position: absolute;
    bottom: 0;
    right: 0;
}

.document__link {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;

    .button {
        margin-left: $width-gap;
        flex-shrink: 0;
    }

    .document__link-title {
        color: $color-text-welsh;

        span {
            display: block;
            text-decoration: underline;
        }

        .english { color: $color-text-english; }

        &.not__link span { text-decoration: none; }
    }

    + .document__link {
        padding-top: $width-gap*0.75;
        margin-top: $width-gap*0.75;
        border-top: 1px dashed $color-line;
    }
}

.document__meta {
    padding: 3.5rem 1.5rem 4rem;
    background: #fff;
    position: relative;

    .page__split-link {
        position: absolute;
        top: 0;
        left: 0;
    }

    @media all and (min-width: 641px) {
        padding-top: 1.5rem;
    }
}

.document__meta-title {
    width: calc(100% - 2rem);
    margin-bottom: $width-gap;
    color: $color-text-welsh;

    > * {
        text-decoration: underline;
        margin: 0;
    }

    .english {
        color: $color-text-english;
        font-size: 1.25rem;
    }
}

.document__download {
    position: absolute;
    top: 0;
    right: 0;
}

.download-button {
    padding: $width-gap/4 $width-gap/2;
    background: $color-primary;
    color: #fff;

    .icon { vertical-align: middle; }

    &:hover { background: darken($color-primary, 2.5%); }
}
