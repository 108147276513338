.event__form {
    background: #fff;
    margin-top: $width-gap;
    padding: $width-gap*1.5;
    overflow: hidden;

    .add-row,
    .delete-row {
        float: right;
    }

    @media all and (min-width: 641px) {
        padding: $width-gap*2.5;
    }
}
