.glitter_page_blocktype_personblock {

    .list__item-details p a {
        margin-right: $width-gap;
    }

    .list__item-body h3 {
        text-decoration: underline;
    }
}
