.latest-carousel__cell {
    width: calc(100% - #{$width-gap*3});
    position: relative;
    min-height: 100%;
    background: #fff;

    &:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        border-right: 1px dashed $color-line;
    }
}

.latest-carousel__cell-image {
    width: 100%;
    height: 10rem;
}

.latest-carousel__cell-body {
    padding: $width-gap*1.5;

    > p {
        text-decoration: underline;
        margin: 0;
        color: $color-text-english;
        padding-right: 1.5rem;

        &:first-child {
            color: $color-text-welsh;
            font-size: 1.125rem;
        }
    }
}

.latest-carousel__details {
    margin-top: $width-gap*0.75;
    font-size: 0.75rem;

    p { margin: 0; }
}

.latest-carousel {

    .flickity-prev-next-button { top: 8.75rem; }

    .flickity-prev-next-button.previous { display: none; }

    &.slide-count__1 {

        .flickity-prev-next-button { display: none; }
    }
}

@media all and (max-width: 640px) {

    &.slide-count__1 .latest-carousel__cell {
        width: 100%;
    }
}

@media all and (min-width: 641px) {

    .latest-carousel__cell { width: 40%; }

    &.slide-count__1,
    &.slide-count__2, {
        .flickity-prev-next-button { display: none; }
    }
}

@media all and (min-width: 961px) {

    .latest-carousel__cell { width: 25%; }

    .latest-carousel .flickity-prev-next-button { display: none; }

    #glitter_column_footer .latest-carousel__cell { width: 50%; }
}


@media all and (min-width: 1201px) {

    #glitter_column_footer .latest-carousel__cell { width: #{100% / 3}; }
}
