.hidden {
    display: none;
}

.row {
    display: flex;
    flex-flow: column nowrap;

    @media all and (min-width: 768px) {
        flex-flow: row nowrap;
        align-items: center;
    }
}
