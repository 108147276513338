// Base sizes
$base-font-size: 1rem !default;
$base-line-height: 1.5 !default;
$base-font: 'Open Sans';
$base-headline-font: 'Slabo 27px';


// Colors
$color-text-welsh: #007F70 !default;
$color-text-english: #4A4A4A !default;

$color-background: #F3F3F3 !default;
$color-footer: #005F54 !default;

$color-primary: #49AA98 !default;
$color-secondary: #0B6158 !default;
$color-tertiary: #007F70 !default;
$color-action: #B45B5B !default;
$color-line: darken($color-background, 10%) !default;


// Size
$width-gap: 1rem;
$width-line: 0.125rem;
$width-column-gap: 1rem;
$width-radius: 0;

$height-logo: 4rem;

// Style
$style-button: none;

// Icon
$icon-width: 1.5em;
$icon-height: $icon-width;


// Headlines

///     FONT SIZE  |  LINE H  |  MARGIN T  |  MARGIN B
$headline-scale: (
    h1: (3.375rem,    1.1,       1.6785em,    0.3em),
    h2: (2.25rem,     1.2,       0.9em,       0.45em),
    h3: (1.5rem,      1.5,       1.33em,      0.66em),
    h4: (1.25rem,     1.1,       1.2em,       0.8em),
    h5: (1.1rem,      1.1,       1.125em,     0.9em),
    h6: (1rem,        1,         1.5em,       0.5em)
) !default;
