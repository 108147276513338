.search__results {
    padding: $width-gap*1.5;
    background: #fff;
}

.search__results-title {
    color: $color-footer;
}

.sidebar__search-form {
    .form-button__multilingual {
        margin-top: $width-gap !important;
    }
}
