.page__nav {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-flow: column nowrap;
    padding: $width-gap*3 0 $width-gap 0;
    flex-shrink: 0;
    background:
        radial-gradient(at 60% bottom, rgba($color-footer,1), rgba($color-secondary, 0.6)),
        radial-gradient(at top left, rgba($color-primary,0.6), rgba($color-footer, 1)),

    ;
    overflow: hidden;
    color: #fff;
    justify-content: space-between;

    @media all and (min-width: 641px) {
        width: 25rem;
        padding-top: $width-gap;
    }
}

.page__nav-header {
    display: none;
    padding: 0 $width-gap;

    @media all and (min-width: 641px) {
        display: flex;
        align-items:center;
        margin-bottom: $width-gap;
        flex-shrink: 0;

        .nav__toggle {
            margin-left: auto;
            margin-right: -$width-gap;
        }
    }
}

.page__nav-footer {
    margin-top: auto;
    padding-top: $width-gap;
    border-top: 1px dashed rgba(#fff, 0.2);
    flex-shrink: 0;
    padding: $width-gap $width-gap 0;

    .social a {
        color: rgba(#fff, 0.75);

        &:hover { color: #fff; }
    }
}

.nav__toggle {
    position: relative;
    z-index: 10;
    cursor: pointer;
    user-select: none;

    .page__content & {
        display: none;
        margin-left: -$width-gap;
        transition: opacity 0.5s cubic-bezier(0.23, 1, 0.32, 1);
        box-shadow: 0.05rem 0.05rem 0.5rem 0 rgba($color-text-english, 0.5);


        .menu__open & {
            opacity: 0;
            pointer-events: none;
        }

        @media all and (min-width: 641px) {
            display: inline-block;
            margin-left: -$width-gap*2;
        }
    }
}

.pagination {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: $width-gap*1.5 0;

    .button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-family: $base-font;
        min-width: 2.5rem;
        text-align: center;
        line-height: 1.5rem;
    }

    @media all and (min-width: 641px) {
        padding-right: $width-gap;

        .page__content-body & {
            padding-right: 0;
        }
    }
}

.pagination__inner {
    display: inline-flex;
    margin: 0 0.25rem;

    .button { margin: 0 0.25rem; }
}

.icon__link {
    display: inline-flex;
    align-items: flex-start;
    line-height: 1;
    width: 100%;

    .icon {
        width: 2.5rem;
        height: 2.5rem;
        display: inline-block;
        margin-right: 0.875rem;
        flex-shrink: 0;
    }

    .icon__link-text {
        display: flex;
        flex-flow: column nowrap;
        font-weight: 600;
        align-items: flex-start;

        .english {
            display: inline;
            font-family: $base-font;
            opacity: 0.75;
            font-weight: normal;
            margin-top: 0.25rem;
        }
    }
}

.site__navigation {
    display: none;
    flex-flow: row nowrap;
    font-size: 0.75rem;

    .icon {
        color: $color-text-welsh;
        flex-shrink: 0;
    }

    .icon__link { overflow: inherit; }

    .icon__link-text {
        color: $color-text-welsh;

        span {
            text-decoration: underline;
            white-space: nowrap;
            display: block;
        }

        .english { color: $color-text-english; }
    }

    @media all and (min-width: 641px) {
        display: flex;
    }
}


.pebbletrail__navigation {
    display: none;

    .icon__link + .icon__link {
        padding-left: $width-gap;
        margin-left: $width-gap;
        position: relative;

        .icon { display: none; }

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0; bottom: 0;
            left: 0;
            border-left: 1px solid $color-line;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: calc(-0.25rem - 2px);
            width: 0.5rem;
            height: 0.5rem;
            border-width: 1px;
            border-style: solid;
            border-color: $color-line $color-line transparent transparent;
            transform: translateY(-50%) rotate(45deg);
            transform-origin: center center;
            background: $color-background;
        }
    }

    @media all and (min-width: 768px) {
        display: flex;
        flex-flow: row nowrap;
    }
}

.nav__primary-holder {
    position: relative;
    height: 100%;
    flex-shrink: 10;
    overflow: hidden;
    padding: 0 1rem;

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: -1px; bottom: -1px;
        left: -$width-gap; right: -$width-gap;
        width: calc(100% + #{$width-gap*2});
        pointer-events: none;
        user-select: none;
        box-shadow: inset 0px 0px $width-gap $width-gap/2 $color-footer;
        z-index: 5;
    }
}

.nav__primary-inner {
    position: relative;
    margin-right: -17px;
    padding-right: 17px;
    height: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
        display: none;
    }
}

.nav__primary {
    display: flex;
    flex-flow: column nowrap;

    > .nav__item:first-of-type { margin-top: $width-gap; }
    > .nav__item:last-of-type { margin-bottom: $width-gap; }

    .nav__item + .nav__item {
        margin-top: 0.75rem;
        padding-top: 0.75rem;
        border-top: 2px dotted rgba(#fff, 0.2);
    }

    .nested__nav {
        padding-left: $width-gap*1.5;
        margin-top: 0.75rem;
        padding-top: 0.75rem;
        border-top: 2px dotted rgba(#fff, 0.2);
    }
}

.nav__list {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 0.875rem;

    .nested__nav {
        width: 100%;
        padding: $width-gap*0.75 0 0 $width-gap*1.5;
        margin-top: $width-gap*0.75;
        border-top: 1px dashed $color-line;
    }

    li {
        display: flex;

        a > .icon {
            display: none;
            width: 1.15em;
            height: 1.5em;
        }

        &.active {
            > a {
                font-weight: 600;

                > .icon {
                    display: block;
                    margin-right: $width-gap/2
                }
            }
        }

        &.open > a { font-weight: 600; }

        &.with__child {
            flex-flow: row wrap;

            a { width: 100%; }
        }

        .nav__list-link {
            display: flex;
            flex-flow: row nowrap;
            color: $color-text-welsh;

            > div {
                text-decoration: underline;

                span:lang(en-GB) {
                    display: block;
                    color: $color-text-english;
                }
            }

            // The styles here are identical to the ones under `> div span`
            // above, but the styling override class is intended to be used on
            // the parent div when we don't have control over the language
            // attributes of the child spans
            > div.nav__list-link--span-styling-override {
                text-decoration: underline;

                span {
                    display: block;
                    color: $color-text-english;
                }
            }
        }

        + li {
            padding-top: $width-gap*0.75;
            margin-top: $width-gap*0.75;
            border-top: 1px dashed $color-line;
        }
    }
}

.footer__nav {
    display: none;
    margin: $width-gap 0;
    flex-flow: row wrap;
    width: calc(100% + #{$width-gap*2});
    margin-left: -$width-gap;
    padding: $width-gap 0;
    border-top: 1px dashed rgba(#fff, 0.75);
    border-bottom: 1px dashed rgba(#fff, 0.75);

    @media all and (min-width: 768px) {
        display: flex;
    }

    > .footer__nav-item {
        flex-shrink: 0;
        width: calc(100% / 3);
        border-right: 1px dashed rgba(#fff, 0.75);

        @media all and (min-width: 768px) and (max-width: 1080px) {
            &:nth-of-type(n+4) {
                border-top: 1px dashed rgba(#fff, 0.75);

                .footer__link { padding-top: $width-gap; }
            }
        }

        @media all and (min-width: 1081px) {
            width: calc(100% / 5);

            &:nth-of-type(n+6) {
                border-top: 1px dashed rgba(#fff, 0.75);

                .footer__link { padding-top: $width-gap; }
            }
        }
    }
}

.footer__link {
    padding: 0 $width-gap $width-gap $width-gap;
    display: block;

    span { display: block; }

    .english { font-weight: 300; }
}

.footer__nested {
    border-top: 1px dashed rgba(#fff, 0.15);

    .footer__link { padding: $width-gap; }

    .footer__nav-item + .footer__nav-item .footer__link {
        padding-top: 0;
    }
}


.nested__nav {
    display: none;

    .open > & { display: block; }
}

.nested__nav-toggle {
    margin-left: auto;
    padding-left: $width-gap;
    align-self: center;

    .icon {
        width: 1.5em;
        height: 1.5em;
        vertical-align: middle;
    }

    .toggle-shut { display: none; }

    .open > a & {

        .toggle-open { display: none; }
        .toggle-shut { display: initial; }
    }
}
