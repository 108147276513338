.page__holder form,
.dynamic-form {

    input:not([type=checkbox]):not([type=radio]):not([type=submit]),
    textarea,
    select {
        appearance: none;
        width: 100%;
        padding: $width-gap;
        border: none;
        background: $color-background;
        vertical-align: top;
        font-size: inherit;
        font-family: inherit;
        -webkit-font-smoothing: auto;
        line-height: 1;
        outline: none;
        color: $color-text-english;
        resize: vertical;
        border-radius: 0;

        &:focus {
            box-shadow: inset 0px 0px 0px 2px rgba($color-primary, 0.5);
        }
    }

    label {
        font-size: 1.125rem;
        font-family: $base-headline-font;
        color: $color-footer;
        display: block;
        margin-bottom: $width-gap/2;
        line-height: 1;

        span {
            font-size: 1rem;
            font-family: $base-font;
            color: $color-text-english;
            margin-left: $width-gap/2;
        }

        .asteriskField { margin-left: 0; }
    }

    input[type=submit], button {
        width: auto;
        appearance: none;
        display: inline-block;
        border: none;
        padding: $width-gap/2;
        text-decoration: $style-button;
        background: $color-primary;
        color: #fff;
        cursor: pointer;
        font-family: $base-headline-font;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        line-height: 1.5;
        margin-top: $width-gap;
        -webkit-font-smoothing: auto;
        border-radius: 0;

        &:hover { background: darken($color-primary, 2.5%); }
    }

    .button__multilingual {
        display: inline-flex;
        background: none;
        padding: 0;
        margin: 0;
        border: none;
        cursor: pointer;

        &:hover { background: none; }
    }

    .dynamic-form {
        padding-top: $width-gap*2;
        margin-top: $width-gap*2;
        border-top: 1px dashed $color-line;
    }

    .form-button__multilingual {
        background: none;
        border: none;
        padding: 0;

        &:hover { background: none; }

        @extend .button__multilingual;
    }

    .field {
        display: block;
        margin-bottom: $width-gap * 1.5;
        @media all and (min-width:640px) {
            &.half {
                float: left;
                width: calc(50% - .5em);
                & + .half {
                    float: left;
                    width: calc(50% - .5em);
                    margin-left: 1em;
                }
            }
        }
    }

    .help-inline,
    .help-block {
        display: block;
        vertical-align: top;
        padding: $width-gap / 3;
        background: $color-primary;
        color: #fff;
        font-size: 0.875rem;
    }

    .help-block {
        background: $color-background;
    }

    .error {
        label, li {
            color: $color-action;
        }
    }

    .errorlist {
        @extend %reset-list;

        margin-bottom: $width-gap /2;
        color: $color-primary;
        font-weight: bold;
    }

    &.inline__form {
        display: flex;
    }
}

.control-group {
    margin: $width-gap 0;
}

.form__section {
    color: $color-text-welsh;
    margin-top: $width-gap*2.5;

    span:lang(en-GB) {
        font-family: $base-font;
        color: $color-text-english;
        font-size: 0.925em;
    }

    &:first-of-type {
        margin-top: 0;
    }
}

.page__content form.contact__form {
    button {
        margin-top: 0;
    }
}

.page__content form.search__form {
    display: inline-flex;

    input:not([type=checkbox]):not([type=radio]):not([type=submit]) {
        background: none;
        width: 100%;
        padding: $width-gap/2 3rem $width-gap/2 $width-gap/2;
        margin-right: -2.5rem;
        margin-left: 0.5rem;
        position: relative;
        z-index: 5;
        max-width: 3rem;
        transition: max-width 0.3s cubic-bezier(0.23, 1, 0.32, 1);
        outline: none;
        cursor: pointer;

        &:focus,
        &:valid {
            max-width: 10rem;
            border-left: 1px solid $color-line;
            background: rgba($color-primary, 0.25);
            cursor: auto;
            margin-left: 1.5rem;

            + button {
                position: relative;
                z-index: 10;
            }
        }
    }

    button {
        width: 2.5rem;
        height: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        margin-top: 0;

        .icon { color: #fff; }
    }
}

.page__nav form.search__form {
    margin-top: $width-gap;

    input:not([type=checkbox]):not([type=radio]):not([type=submit]) {
        background: none;
        width: 100%;
        padding: $width-gap/2 3.5rem $width-gap/2 $width-gap/2;
        margin-right: -3rem;
        background: rgba($color-primary, 0.25);
        outline: none;
        color: inherit;

        &:focus {
            box-shadow: inset 0px 0px 0px 2px $color-primary;
        }
    }
    button {
        width: 3rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        background: none;
        margin-top: 0;
    }
}
