.glitter_page_blocktype_calltoaction,
.glitter_page_blocktype_coredocumentsblock {
    .calltoaction__image {
        min-height: 17.5rem;
    }

    .calltoaction__body {
        background: #fff;
        padding: $width-gap*2;
    }

    .calltoaction__list {
        margin: $width-gap 0 $width-gap*2 0;
    }

    .calltoaction__title {
        display: flex;
        padding-bottom: $width-gap;
        margin-bottom: $width-gap;
        border-bottom: 1px dashed $color-line;

        .icon {
            width: 3rem;
            height: 3rem;
            margin-right: $width-gap;
            color: $color-tertiary;
        }
        h4 {
            color: $color-text-welsh;
            margin-bottom: 0;
        }

        p {
            margin: 0.25rem 0 0 0;
            color: $color-text-english;
            font-size: 1.075rem;
        }
    }

    .welsh__content {
        color: $color-text-welsh;
        margin-bottom: $width-gap/2;
    }

    .english__content {
        color: $color-text-english;
        margin-bottom: $width-gap*1.5;
    }

    @media all and (min-width: 641px) {
        display: flex;
        flex-flow: row nowrap;

        > * {
            width: 50%;
        }

        &.align-right {
            .calltoaction__body {
                order: -1;
            }
        }
    }

    @media all and (min-width: 1361px) {
        .calltoaction__body {
            width: calc(100% / 3);
            flex-shrink: 0;
        }

        .calltoaction__image {
            width: 100%;
        }
    }

    @media all and (min-width: 961px) {

        body.home &,
        .page__billboard & {
            .calltoaction__body {
                width: calc(100% / 3);
                flex-shrink: 0;
            }

            .calltoaction__image {
                width: 100%;
            }
        }
    }
}


.glitter_page_blocktype_coredocumentsblock {
    @media all and (max-width: 641px) {

        .calltoaction__image { display: none }
    }
}
