body.home {

    .site__navigation .icon__link {  display: none; }

    .page__footer { margin-top: 0; }

    #glitter_column_content {

        .glitter_page_block:not(.glitter_page_blocktype_carouselblock):not(.glitter_page_blocktype_billboard):not(.glitter_page_blocktype_iconbannerblock) {
            max-width: 75rem;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .page__buttons {
        @media all and (min-width: 641px) {
            padding-right: $width-gap;
        }
    }

    &.white-logo {

        .page__header {
            margin-bottom: 0;
            padding: 0;

            @media all and (min-width: 641px) {
                padding: $width-gap;
            }
        }

        .page__header-top {
            .page__logo { filter: brightness(0) invert(1); }
            .search__form input { color: #fff; }
        }

        .page__billboard {

            .carousel {
                margin-top: -2.715rem;

                .carousel__body .carousel__cell .carousel__cell-content {
                    margin-top: $width-gap*5;
                }

                @media all and (min-width: 641px) {
                    margin-top: -9.125rem;

                    .carousel__body .carousel__cell .carousel__cell-content {
                        margin-top: $width-gap*11.5;
                    }
                }
                @media all and (min-width: 961px) {
                    .carousel__body .carousel__cell .carousel__cell-content {
                        margin-top: $width-gap*13;
                    }
                }
            }

            // admin specific
            .glitter-toolbar + .carousel { margin-top: 0; }
        }
    }
}
