.mobile__nav-header {
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: rgba($color-footer, 0.75);
    z-index: 10;

    .close { display: none; }

    .menu__open & {

        .open { display: none; }
        .close { display: inline-flex; }
    }

    .page__logo {
        background-size: 75%;
        background-position: right 0.5rem center;
        padding: 0.25rem 0;
    }

    @media all and (min-width: 641px) {
        display: none;
    }
}

.page__header {
    position: relative;
    padding: $width-gap;
    z-index: 15;
    margin-top: 2.715rem;

    .custom__select-holder {
        display: inline-block;
        width: 100%;
        min-width: 16.25rem;
    }

    @media all and (min-width: 641px) {
        padding: $width-gap;
        margin-top: 0;

        .custom__select-holder {
            margin-top: 1rem;
            margin-left: $width-gap*0.75;
            width: auto;
        }

        &.sticky {
            min-height: 9.1875rem;

            .nav__toggle {
                position: fixed;
                top: 1rem;
                left: 0;
                margin-left: 0;
            }

            .custom__select-holder {
                margin-left: 7.2rem;
            }
        }
    }

    @media all and (min-width: 961px) {
        .custom__select-holder { display: none; }
    }
}

.page__header-top {
    display: none;
    margin-bottom: $width-gap;

    @media all and (min-width: 641px) {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .site__navigation { margin-left: auto; }

        .custom__select-holder {
            width: auto;
        }
    }
}
