.article {
    background: #fff;
    overflow: hidden;

    .page__split { padding: 0; }
}

.article__social {
    font-size: 1rem;
    color: $color-primary;
    margin-left: auto;

    @media all and (min-width: 641px) {
        margin-left: $width-gap;
    }
}

.article__image {
    height: 15rem;

    @media all and (min-width: 961px) {
        height: 25rem;
    }
}

.article__meta-bar {
    display: flex;
    flex-flow: row wrap;
    padding: $width-gap/2 $width-gap*1.5;
    justify-content: space-between;
    align-items: center;
    font-size: 0.75rem;
    position: relative;

    &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: $width-gap*1.5;
        right: $width-gap*1.5;
        border-top: 1px dashed $color-line;
    }

    .article__meta {
        width: 100%;
        order: 3;
        margin: $width-gap 0 0 auto;

        span { margin-right: 0.75em; }
        .article__meta-span--welsh { margin-right: 0; }

        a { text-decoration: underline; }

        @media all and (min-width: 641px) {
            margin: 0 0 0 0;
        }
    }

    .social {
        color: $color-text-welsh;
    }

    .page__split-link {
        margin: -#{$width-gap/2} 0 0 -#{$width-gap*1.5};
    }

    @media all and (min-width: 641px) {
        flex-flow: row nowrap;

        .article__meta {
            order: initial;
        }
    }
}
