.glitter_page_blocktype_masonryblock {
    margin: 3rem auto;

    .grid {
        margin: 0 auto;

        .grid-item {
            margin-bottom: 1rem;
            width: 100%;
        }

        .grid-sizer {
            width: 45%;

            @media screen and (min-width: 768px) {
                width: 24%;

                &.grid-length--4 {
                    width: 30%;
                }

                &.grid-length--10 {
                    width: 18%;
                }
            }
        }
    }

    // css masonry grid styles
    .css-masonry-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, 45%);
        grid-template-rows: masonry;
        grid-gap: 1rem;
        justify-content: center;

        @media screen and (min-width: 768px) {
            grid-template-columns: repeat(auto-fit, 24%);
        }
    }
}
