.glitter_page_blocktype_bannerblock {
    display: flex;
    flex-flow: row wrap;

    @media all and (min-width: 941px) {
        flex-flow: row nowrap;
    }
}

.banner {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    flex-grow: 10;
    flex-shrink: 0;

    @media all and (min-width: 641px) {
        position: relative;
        width: 50%;
    }

    @media all and (min-width: 641px) and (max-width: 940px) {
        &:nth-of-type(2n):before {
            content: '';
            display: block;
            position: absolute;
            top: 0; bottom: 0; left: 0;
            border-left: 1px dashed $color-line;
        }
    }

    @media all and (min-width: 941px) {
        width: 25%;

        &:nth-of-type(n+2):before {
            content: '';
            display: block;
            position: absolute;
            top: 0; bottom: 0; left: 0;
            border-left: 1px dashed $color-line;
        }
    }
}

.banner__image {
    height: 10rem;
}

.banner__content {
    padding: $width-gap*1.5;
    color: $color-text-welsh;
    background: #fff;
    flex: 1;

    p {
        text-decoration: underline;
        margin: 0;
        font-weight: normal;
        font-size: 1.125rem;
    }

    p:last-of-type {
        color: $color-text-english;
        margin-top: 0.25rem;
        font-size: 1rem;
    }
}
