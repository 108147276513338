img,
video {
    width: 100%;
    height: auto;
    vertical-align: top;
}

figure {
    margin: 0;
}

figcaption {
    display: block;
    vertical-align: top;
    padding: $width-gap / 3;
    background: $color-primary;
    color: #fff;
    font-size: 0.875rem;
}
