.page-footer {
    background: $color-footer;
    box-shadow: 0px 100vh 0px 100vh $color-footer;
    padding: $width-gap*2 0;
    position: relative;
    color: #fff;
    margin-top: $width-gap*3.5;

    > .container {
        width: calc(100% - #{$width-gap*2});
        padding: 0;
        overflow: hidden;
    }

    .social { margin: 1.5rem 0 0 0; }


    @media all and (min-width: 768px) {
        .social { margin: 0 0 0 auto; }
    }
}

.page-footer__legal {
    font-size: 0.75rem;

    a { text-decoration: underline; }
}

.page-footer__social {
    @media all and (min-width: 768px) {
        display: flex;
    }
}

.footer__copyright {
    opacity: 0.25;
}

.footer__bar {
    display: flex;
    width: 100%;
    flex-flow: column nowrap;

    @media all and (min-width: 961px) {
        flex-flow: row nowrap;
        align-items: center;

        .footer__brand {
            margin-right: $width-gap*1.5;
            flex-shrink: 0;
            padding-right: $width-gap*1.5;
            border-right: 1px dashed rgba(#fff, 0.75);
        }

        .footer__section {
            width: 100%;
        }
    }
}

.page-footer .social a {
    color: rgba(#fff, 0.75);

    &:hover { color: rgba(#fff, 1); }
}

.footer__section {
    margin-top: $width-gap*2;
    padding: 1rem 0;
    border-top: 1px dashed rgba(#fff, 0.2);

    p {
        font-size: 0.75rem;
        max-width: 42.5rem;

        &:first-of-type { font-weight: bold; }
    }

    a {
        text-decoration: underline;
    }

    .footer__section-title { font-size: 1rem; }

    @media all and (min-width: 961px) {
        margin-top: 0;
        border-top: none;
    }
}
