@import 'lists';
@import 'docs';
@import 'news';
@import 'search';
@import 'events';
@import 'vacancies';

.meta__list {
    list-style: none;
    padding: 0;
    margin: 0;

    li + li {
        padding-top: $width-gap*0.75;
        margin-top: $width-gap*0.75;
        border-top: 1px dashed $color-line;
    }
}
