.glitter_page_blocktype_billboard {
    color: #fff;
    padding: $width-gap*4 $width-gap*2;
    display: flex;
    align-items: center;
    min-height: 20rem;

    .billboard__container {
        width: 100%;
        max-width: 75rem;
        margin: auto;
    }

    .billboard__content {
        max-width: 47.5rem;
        text-shadow: 0.0625rem 0.125em 0.5rem rgba($color-text-english, 0.5);
    }

    a {
        display: flex;
        flex-flow: column nowrap;
        text-decoration: underline;
    }

    h3 {
        margin-top: 0;
        font-size: 1.75rem;
        font-family: $base-font;
    }

}
