.list__item-list,
.list__item-grid {
    margin-bottom: $width-gap;
}

.list__item-grid {
    display: flex;
    flex-flow: row wrap;

    @media all and (min-width: 641px) and (max-width: 1360px) {
        .list__item-card,
        .list__item-panel {
            width: calc(50% - #{$width-gap/2});
            margin-right: $width-gap;

            &:nth-of-type(2n) { margin-right: 0; }
            &:nth-of-type(n+3) { margin-top: $width-gap; }
        }
    }

    @media all and (min-width: 1361px) {
        .list__item-card,
        .list__item-panel {
            width: calc((100% / 3) - #{$width-gap*2/3});
            margin-right: $width-gap;

            &:nth-of-type(3n) { margin-right: 0; }
            &:nth-of-type(n+4) { margin-top: $width-gap; }
        }
    }
}

.list__item-card,
.list__item-panel {
    display: flex;
    width: 100%;

    + .list__item-panel {
        margin-top: $width-gap;
    }

    .list__item-welsh,
    .list__item-english {
        padding: $width-gap*1.5;
        width: 100%;
    }

    .list__item-welsh,
    .list__item-details {
        color: $color-text-welsh;
    }

    .list__item-english {
        padding-top: 0;
        color: $color-text-english;
    }

    .list__item-external {
        display: inline-flex;
        align-items: center;
        position: absolute;
        padding: 0 0.5rem;
        top: 0;
        right: 0;
        min-width: 0;
        max-width: 2rem;
        height: 2rem;
        background: $color-action;
        color: #fff;
        font-family: $base-headline-font;
        box-shadow: 0.05rem 0.05rem 0.5rem 0 rgba($color-text-english, 0.5);
        transition: 0.2s max-width ease-in;
        will-change: max-width;
        user-select: none;

        .icon {
            flex-shrink: 0;
            width: 1.125rem;
            height: 1.125rem;
            margin-right: 0.75rem;
        }

        span {
            opacity: 0;
            transition: 0.2s opacity;
        }
    }

    &:hover .list__item-external {
        max-width: 100%;

        span {
            opacity: 1;
        }
    }
}

.list__item-card {
    flex-flow: column nowrap;

    .list__item-body {
        flex-flow: column nowrap;
        flex-grow: 10;
    }
}

.list__item-panel {
    flex-flow: column nowrap;

    .search__results & {

        + .list__item-panel {
            border-top: 1px dashed $color-line;
        }

        .list__item-details,
        .list__item-welsh,
        .list__item-english {
            padding: $width-gap 0;
        }

        .list__item-welsh { padding-bottom: 0; }

        .list__item-english { padding-top: 0; }

        .list__item-details {
            padding-bottom: 0;
            color: $color-text-english;

            > span {
                margin-right: $width-gap*0.675;
            }

            &:after { content: none; }
        }
    }

    @media all and (min-width: 641px) {
        flex-flow: row nowrap;

        .list__item-body {
            width: calc(200% /3);
            flex-grow: 10;
        }
        .list__item-image {
            width: calc(100% /3);
            flex-shrink: 0;
        }

        .list__item-welsh,
        .list__item-english {
            width: 50%;
            padding: $width-gap*1.5;
            position: relative;
        }

        .list__item-english:before {
            content: '';
            display: block;
            position: absolute;
            top: $width-gap;
            bottom: $width-gap;
            left: 0;
            border-left: 1px dashed $color-line;
        }

        .search__results & {

            .list__item-details,
            .list__item-welsh,
            .list__item-english {
                padding: $width-gap 0;
            }

            .list__item-welsh { padding-right: $width-gap*1.5; }
            .list__item-english { padding-left: $width-gap*1.5; }
        }
    }
}

.list__item-body {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    background: #fff;
}

.list__item-details {
    padding: $width-gap*1.5;
    position: relative;
    width: 100%;

    a {
        text-decoration: underline;
        font-family: $base-headline-font;
        font-size: 1.125rem;

        &.button,
        &.button__multilingual {
            text-decoration: none;
        }
    }

    p {
        margin: 0.25rem 0;

        &:first-child { margin-top: 0; }
        &:last-child { margin-bottom: 0; }
    }

    &:first-child:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: $width-gap*1.5;
        right: $width-gap*1.5;
        border-bottom: 1px dashed $color-line;
    }

    &:last-child:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: $width-gap*1.5;
        right: $width-gap*1.5;
        border-top: 1px dashed $color-line;
    }

    .list__item-date {
        margin-bottom: 0;

        + p { margin-top: 0.25rem; }
    }
}

.list__item-image {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    min-height: 12.5rem;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;

    .list__item-meta-wrapper {
        display: flex;
        margin-top: auto;
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;

        .download__button {
            margin-left: $width-gap;
            background: $color-primary;

            &:hover {
                background: darken($color-primary, 2.5%);
            }
        }
    }

    .list__item-meta {
        display: inline-block;
        margin-top: auto;
        background: rgba($color-footer, 0.875);
        padding: $width-gap/2 $width-gap;
        color: #fff;
        max-width: 17.5rem;

        div {
            font-size: 0.875rem;
        }
    }
}

.list__item-english {
    .list__item-title,
    h3 {
        font-size: 1.35rem;
        line-height: 2.2rem;
    }

    h3 { margin-bottom: 0.175em; }

    .list__item-title { margin-bottom: 0.325em; }
}

.list__item-title {
    margin-bottom: 0.25rem;
    text-decoration: underline;

    .list__item-english & {
        font-size: 1.35rem;
        line-height: 2.2rem;
    }

    + * { margin-top: 0; }
}
