.carousel {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 100%;

    .flickity-prev-next-button {
        border-radius: 0;
        background: $color-primary;
        width: 2.5rem;
        height: 2.5rem;
        transform: none;
        top: auto;
        bottom: 6.25rem;
        z-index: 10;

        &.previous { left: 0; }
        &.next { right: 0; }
        &:disabled { opacity: 1; }

        &:active,
        &:hover {
            background: darken($color-primary, 2.5%);
            opacity: 1;
        }

        .arrow { fill: #fff; }
    }
}

.carousel__cell-content {
    position: relative;
    z-index: 5;
}

.carousel__body {
    width: 100%;

    .carousel__cell-image,
    .carousel__cell:after {
        position: absolute;
        top: 0; right: -$width-gap; bottom: 0; left: 0;
    }

    .carousel__cell-image {
        height: 100%;

        &.blurred {
            clip-path: inset(calc(100% - 6.25rem) 0 0 0);

            @media all and (min-width: 768px) {
                display: block;
                clip-path: inset(calc(100% - 6.25rem) 0 0 0);
                overflow: hidden;
            }

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                display: none;
            }
        }
    }

    .carousel__cell-content {
        margin: $width-gap*6 $width-gap*1.5 $width-gap*9.5 $width-gap*1.5;
    }

    .carousel__cell {
        width: 100%;
        min-height: 100%;
        color: #fff;
        position: relative;
        margin-right: 0px;

        &.is-selected { z-index: 10; }

        &:after {
            content: '';
            display: block;
            background: linear-gradient(45deg, rgba(0,0,0,0.625) 0%,rgba(0,0,0,0.3) 100%);
        }
    }
}

@supports (-ms-ime-align:auto) {
    .carousel__body .carousel__cell-image.blurred {
        display: none;
    }
}

.carousel__nav {
    bottom: 0;
    color: #fff;
    background: rgba($color-primary, 0.5);

    .flickity-prev-next-button {
        top: -1.375rem;
        transform: none;
        display: none;
    }

    &.flickity-enabled {
        position: absolute;
        z-index: 5;
        width: 100%;
    }

    .carousel__cell {
        padding: $width-gap $width-gap $width-gap*2 $width-gap;
        cursor: pointer;
        min-height: 6.25rem;
        position: relative;
        width: 75%;
        overflow: hidden;

        + .carousel__cell:before {
            content: '';
            display: block;
            position: absolute;
            top: 0; left: 0; bottom: 0;
            border-left: 1px dashed $color-line;
            z-index: 20;
        }

        &.is-nav-selected {
            background: rgba(#fff, 0.75);
            color: $color-text-welsh;
        }

        @media all and (min-width: 641px) { width: 50%; }

        @media all and (min-width: 961px) { width: calc(100% / 3); }
    }

    .carousel__title {
        display: flex;
        align-items: flex-start;

        .carousel__title-text {
            width: 100%;
            min-width: 0;
            flex: 1;
            overflow: hidden;
        }

        .icon {
            width: 2rem;
            height: 2rem;
            margin-right: $width-gap;
        }

        p {
            font-size: 1rem;
            text-decoration: underline;
            margin: 0;
            line-height: 1.125;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
            overflow: hidden;

            &.english {
                font-size: 0.93rem;
                margin-top: 0.5em;
            }

            @media all and (min-width: 961px) {
                font-size: 1.125rem;

                &.english { font-size: 1rem; }
            }
        }
    }
}

.carousel__title {
    text-shadow: 1px 1px rgba($color-tertiary, 0.125);
    max-width: 42.5rem;

    h2 {
        margin: 0;
    }
    h3 {
        margin: 0 0 $width-gap*2 0;
        font-family: $base-font;
        font-size: 1.75rem;
    }

    @media all and (max-width: 767px) {
        h2 { font-size: 1.5rem; }
        h3 {
            font-size: 1.125rem;
            margin-top: 0.5rem;
        }

    }

    .carousel__nav & {
        h4 { margin-bottom: 0; }
    }
}
