/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

/* GLOBAL STYLES */
* {
  box-sizing: border-box; }

:first-child {
  margin-top: 0; }

:last-child {
  margin-bottom: 0; }

/* GLOBAL IMPORTS */
body {
  font-family: "Open Sans", sans-serif;
  line-height: 1.5; }

h1, .h1 {
  font-size: 3.375rem;
  line-height: 1.1;
  margin-top: 1.6785em;
  margin-bottom: 0.3em; }

h2, .h2 {
  font-size: 2.25rem;
  line-height: 1.2;
  margin-top: 0.9em;
  margin-bottom: 0.45em; }

h3, .h3 {
  font-size: 1.5rem;
  line-height: 1.5;
  margin-top: 1.33em;
  margin-bottom: 0.66em; }

h4, .h4 {
  font-size: 1.25rem;
  line-height: 1.1;
  margin-top: 1.2em;
  margin-bottom: 0.8em; }

h5, .h5 {
  font-size: 1.1rem;
  line-height: 1.1;
  margin-top: 1.125em;
  margin-bottom: 0.9em; }

h6, .h6 {
  font-size: 1rem;
  line-height: 1;
  margin-top: 1.5em;
  margin-bottom: 0.5em; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-weight: normal; }

h1, .h1,
h2, .h2,
h3, .h3 {
  font-family: "Slabo 27px", sans-serif; }

*[class*=english] h1, *[class*=english] .h1,
*[class*=english] h2, *[class*=english] .h2,
*[class*=english] h3, *[class*=english] .h3 {
  font-family: "Open Sans"; }

a {
  color: inherit;
  text-decoration: none; }

hr {
  border: none;
  height: 0.125rem;
  background: #F3F3F3; }

.brand__font {
  font-family: "Slabo 27px"; }

.english__font {
  font-family: "Open Sans"; }

.faded {
  opacity: 0.5; }

.sr__text,
.sr__input,
.sr__label {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px); }

.social, .page__holder form .errorlist,
.dynamic-form .errorlist {
  list-style: none;
  margin: 0;
  padding: 0; }

.hidden {
  display: none; }

.row {
  display: flex;
  flex-flow: column nowrap; }
  @media all and (min-width: 768px) {
    .row {
      flex-flow: row nowrap;
      align-items: center; } }

.container {
  width: 100%;
  margin: auto;
  padding: 0 1rem; }

.icon {
  width: 1.5em;
  height: 1.5em;
  fill: currentColor; }

.social {
  display: flex;
  flex-flow: row nowrap; }
  .social > li + li {
    margin-left: 1rem; }
  .social.social-buttons li a {
    display: inline-block;
    background: #007F70;
    color: #fff;
    padding: 0.5rem;
    line-height: 1; }
    .social.social-buttons li a:hover {
      background: #007265; }
  .social.social-buttons li + li {
    margin-left: 0.75rem; }

.button,
.btn {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem;
  text-decoration: none;
  background: #49AA98;
  color: #fff;
  font-family: "Slabo 27px";
  letter-spacing: 0.05em; }
  .button .english,
  .btn .english {
    display: block;
    font-family: "Open Sans";
    opacity: 0.75;
    font-size: 0.875em;
    margin-left: 0.5em; }
  .button.baseline,
  .btn.baseline {
    align-items: baseline; }
  .button.active,
  .btn.active {
    background: #007F70; }
  .button.disabled,
  .btn.disabled {
    opacity: 0.5; }
  .button.outline,
  .btn.outline {
    background: none;
    box-shadow: inset 0px 0px 0px 2px #49AA98;
    color: #007F70; }
    .button.outline:hover,
    .btn.outline:hover {
      color: #fff; }
  .button.icon-button,
  .btn.icon-button {
    line-height: 1; }
  .button.with-icon .icon,
  .btn.with-icon .icon {
    margin-left: 0.5rem; }
  .button:hover,
  .btn:hover {
    background: #45a190; }
  .button.action,
  .btn.action {
    background: #B45B5B;
    color: #fff; }
    .button.action:hover,
    .btn.action:hover {
      background: #b05252; }
  .button.secondary,
  .btn.secondary {
    background: #0B6158;
    color: #fff; }
    .button.secondary:hover,
    .btn.secondary:hover {
      background: #0a564e; }
  @media all and (min-width: 641px) {
    .button .english,
    .btn .english {
      display: inline;
      width: auto;
      margin-left: 0.5em; } }

.button__row,
.btn__row {
  margin-top: 1rem; }

.button__multilingual, .page__holder form .form-button__multilingual,
.dynamic-form .form-button__multilingual {
  font-family: "Slabo 27px";
  display: inline-flex;
  flex-flow: column nowrap;
  text-transform: uppercase;
  align-items: flex-start;
  font-size: 1.125rem;
  color: #fff;
  appearance: none;
  background: none;
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer; }
  .button__multilingual span, .page__holder form .form-button__multilingual span,
  .dynamic-form .form-button__multilingual span {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    background: #49AA98;
    padding: 0.5rem 1rem 0.5rem 1rem; }
    .button__multilingual span .icon, .page__holder form .form-button__multilingual span .icon,
    .dynamic-form .form-button__multilingual span .icon {
      vertical-align: top;
      margin-left: 0.25em; }
  .button__multilingual .english, .page__holder form .form-button__multilingual .english,
  .dynamic-form .form-button__multilingual .english {
    font-family: "Open Sans";
    text-transform: none;
    font-size: 0.875em;
    margin-top: -0.75rem; }
  .button__multilingual:hover span, .page__holder form .form-button__multilingual:hover span,
  .dynamic-form .form-button__multilingual:hover span {
    background: #45a190; }

.page__buttons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end; }
  @media all and (min-width: 641px) {
    .page__buttons {
      padding-right: 1rem; } }
  @media all and (min-width: 1221px) {
    .page__buttons {
      padding-right: 0; }
      .page__content-holder + .page__buttons {
        padding-right: 1rem; } }

img,
video {
  width: 100%;
  height: auto;
  vertical-align: top; }

figure {
  margin: 0; }

figcaption {
  display: block;
  vertical-align: top;
  padding: 0.33333rem;
  background: #49AA98;
  color: #fff;
  font-size: 0.875rem; }

.page__logo {
  display: inline-block;
  height: 4rem;
  vertical-align: top;
  flex-shrink: 0; }
  .page__logo img {
    width: auto;
    height: 100%; }
  .page__logo.inverted {
    filter: brightness(0) invert(1); }
  .page__logo.small {
    height: 3rem; }

.page__crests {
  display: inline-block;
  width: 8rem;
  height: 5rem;
  background: url(../img/crests.svg) center center/contain no-repeat; }

.contact__form {
  width: 100%;
  padding: 1.5rem;
  background: #fff; }
  @media all and (min-width: 641px) {
    .contact__form {
      padding: 1.5rem 2.5rem 2.5rem; } }

.page__holder form input:not([type=checkbox]):not([type=radio]):not([type=submit]),
.page__holder form textarea,
.page__holder form select,
.dynamic-form input:not([type=checkbox]):not([type=radio]):not([type=submit]),
.dynamic-form textarea,
.dynamic-form select {
  appearance: none;
  width: 100%;
  padding: 1rem;
  border: none;
  background: #F3F3F3;
  vertical-align: top;
  font-size: inherit;
  font-family: inherit;
  -webkit-font-smoothing: auto;
  line-height: 1;
  outline: none;
  color: #4A4A4A;
  resize: vertical;
  border-radius: 0; }
  .page__holder form input:not([type=checkbox]):not([type=radio]):not([type=submit]):focus,
  .page__holder form textarea:focus,
  .page__holder form select:focus,
  .dynamic-form input:not([type=checkbox]):not([type=radio]):not([type=submit]):focus,
  .dynamic-form textarea:focus,
  .dynamic-form select:focus {
    box-shadow: inset 0px 0px 0px 2px rgba(73, 170, 152, 0.5); }

.page__holder form label,
.dynamic-form label {
  font-size: 1.125rem;
  font-family: "Slabo 27px";
  color: #005F54;
  display: block;
  margin-bottom: 0.5rem;
  line-height: 1; }
  .page__holder form label span,
  .dynamic-form label span {
    font-size: 1rem;
    font-family: "Open Sans";
    color: #4A4A4A;
    margin-left: 0.5rem; }
  .page__holder form label .asteriskField,
  .dynamic-form label .asteriskField {
    margin-left: 0; }

.page__holder form input[type=submit], .page__holder form button,
.dynamic-form input[type=submit],
.dynamic-form button {
  width: auto;
  appearance: none;
  display: inline-block;
  border: none;
  padding: 0.5rem;
  text-decoration: none;
  background: #49AA98;
  color: #fff;
  cursor: pointer;
  font-family: "Slabo 27px";
  text-transform: uppercase;
  letter-spacing: 0.05em;
  line-height: 1.5;
  margin-top: 1rem;
  -webkit-font-smoothing: auto;
  border-radius: 0; }
  .page__holder form input[type=submit]:hover, .page__holder form button:hover,
  .dynamic-form input[type=submit]:hover,
  .dynamic-form button:hover {
    background: #45a190; }

.page__holder form .button__multilingual, .page__holder form .form-button__multilingual,
.dynamic-form .button__multilingual,
.dynamic-form .page__holder form .form-button__multilingual, .page__holder form
.dynamic-form .form-button__multilingual, .dynamic-form .form-button__multilingual {
  display: inline-flex;
  background: none;
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer; }
  .page__holder form .button__multilingual:hover, .page__holder form .form-button__multilingual:hover,
  .dynamic-form .button__multilingual:hover, .dynamic-form .form-button__multilingual:hover {
    background: none; }

.page__holder form .dynamic-form,
.dynamic-form .dynamic-form {
  padding-top: 2rem;
  margin-top: 2rem;
  border-top: 1px dashed #dadada; }

.page__holder form .form-button__multilingual,
.dynamic-form .form-button__multilingual {
  background: none;
  border: none;
  padding: 0; }
  .page__holder form .form-button__multilingual:hover,
  .dynamic-form .form-button__multilingual:hover {
    background: none; }

.page__holder form .field,
.dynamic-form .field {
  display: block;
  margin-bottom: 1.5rem; }
  @media all and (min-width: 640px) {
    .page__holder form .field.half,
    .dynamic-form .field.half {
      float: left;
      width: calc(50% - .5em); }
      .page__holder form .field.half + .half,
      .dynamic-form .field.half + .half {
        float: left;
        width: calc(50% - .5em);
        margin-left: 1em; } }

.page__holder form .help-inline,
.page__holder form .help-block,
.dynamic-form .help-inline,
.dynamic-form .help-block {
  display: block;
  vertical-align: top;
  padding: 0.33333rem;
  background: #49AA98;
  color: #fff;
  font-size: 0.875rem; }

.page__holder form .help-block,
.dynamic-form .help-block {
  background: #F3F3F3; }

.page__holder form .error label, .page__holder form .error li,
.dynamic-form .error label,
.dynamic-form .error li {
  color: #B45B5B; }

.page__holder form .errorlist,
.dynamic-form .errorlist {
  margin-bottom: 0.5rem;
  color: #49AA98;
  font-weight: bold; }

.page__holder form.inline__form,
.dynamic-form.inline__form {
  display: flex; }

.control-group {
  margin: 1rem 0; }

.form__section {
  color: #007F70;
  margin-top: 2.5rem; }
  .form__section span:lang(en-GB) {
    font-family: "Open Sans";
    color: #4A4A4A;
    font-size: 0.925em; }
  .form__section:first-of-type {
    margin-top: 0; }

.page__content form.contact__form button {
  margin-top: 0; }

.page__content form.search__form {
  display: inline-flex; }
  .page__content form.search__form input:not([type=checkbox]):not([type=radio]):not([type=submit]) {
    background: none;
    width: 100%;
    padding: 0.5rem 3rem 0.5rem 0.5rem;
    margin-right: -2.5rem;
    margin-left: 0.5rem;
    position: relative;
    z-index: 5;
    max-width: 3rem;
    transition: max-width 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    outline: none;
    cursor: pointer; }
    .page__content form.search__form input:not([type=checkbox]):not([type=radio]):not([type=submit]):focus, .page__content form.search__form input:not([type=checkbox]):not([type=radio]):not([type=submit]):valid {
      max-width: 10rem;
      border-left: 1px solid #dadada;
      background: rgba(73, 170, 152, 0.25);
      cursor: auto;
      margin-left: 1.5rem; }
      .page__content form.search__form input:not([type=checkbox]):not([type=radio]):not([type=submit]):focus + button, .page__content form.search__form input:not([type=checkbox]):not([type=radio]):not([type=submit]):valid + button {
        position: relative;
        z-index: 10; }
  .page__content form.search__form button {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-top: 0; }
    .page__content form.search__form button .icon {
      color: #fff; }

.page__nav form.search__form {
  margin-top: 1rem; }
  .page__nav form.search__form input:not([type=checkbox]):not([type=radio]):not([type=submit]) {
    background: none;
    width: 100%;
    padding: 0.5rem 3.5rem 0.5rem 0.5rem;
    margin-right: -3rem;
    background: rgba(73, 170, 152, 0.25);
    outline: none;
    color: inherit; }
    .page__nav form.search__form input:not([type=checkbox]):not([type=radio]):not([type=submit]):focus {
      box-shadow: inset 0px 0px 0px 2px #49AA98; }
  .page__nav form.search__form button {
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    background: none;
    margin-top: 0; }

.event__form {
  background: #fff;
  margin-top: 1rem;
  padding: 1.5rem;
  overflow: hidden; }
  .event__form .add-row,
  .event__form .delete-row {
    float: right; }
  @media all and (min-width: 641px) {
    .event__form {
      padding: 2.5rem; } }

.custom__select-holder {
  position: relative; }
  .custom__select-holder:focus {
    outline: none; }

.custom__select-search .custom__option,
.custom__select-search .custom__select-toggle {
  font-size: 1.125rem;
  font-family: "Slabo 27px";
  color: #007F70; }
  .custom__select-search .custom__option span,
  .custom__select-search .custom__select-toggle span {
    font-size: 1rem;
    font-family: "Open Sans";
    margin-left: 0.25rem;
    color: #4A4A4A; }
  .custom__select-search .custom__option .icon,
  .custom__select-search .custom__select-toggle .icon {
    font-size: 1rem; }

.custom__select-dropdown {
  display: none;
  background: #fff;
  border-top: 0.125rem solid #49AA98;
  position: absolute;
  top: 100%;
  min-width: 100%;
  z-index: 20;
  max-height: 13rem;
  overflow: auto;
  box-shadow: 0px 0px 0.75rem 0px rgba(74, 74, 74, 0.2); }
  .custom__select-open + .custom__select-dropdown {
    display: block; }

.custom__option,
.custom__select-toggle {
  color: #4A4A4A;
  padding: 0.5rem;
  cursor: pointer; }
  .custom__option span,
  .custom__select-toggle span {
    font-size: 1.125rem;
    font-family: "Slabo 27px";
    color: #007F70;
    margin-right: 0.25em; }
  .custom__option + .custom__option,
  .custom__select-toggle + .custom__option {
    border-top: 1px dashed #dadada; }
  .custom__option:hover,
  .custom__select-toggle:hover {
    background: rgba(73, 170, 152, 0.0625); }

.custom__select-toggle {
  background: #fff;
  display: flex;
  justify-content: space-between; }
  .custom__select-toggle .icon {
    margin-left: 1rem;
    color: #007F70; }
  .custom__select-toggle .toggle-close {
    display: none; }
  .custom__select-toggle.custom__select-open .toggle-open {
    display: none; }
  .custom__select-toggle.custom__select-open .toggle-close {
    display: block; }
  .custom__select-toggle:focus {
    outline: none; }

.hidden__field {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px); }

@media all and (min-width: 768px) {
  .glitter_page_blocktype_textimageblock {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start; }
    .glitter_page_blocktype_textimageblock img {
      width: calc(50% - $width-gap/2);
      height: auto;
      margin-right: 1rem; }
    .glitter_page_blocktype_textimageblock.right .text-image-content {
      order: -1;
      margin-right: 1rem; }
    .glitter_page_blocktype_textimageblock.right img {
      margin-right: 0; } }

@media all and (max-width: 740px) {
  .glitter_page_blocktype_textimageblock {
    flex-flow: row wrap; }
    .glitter_page_blocktype_textimageblock img {
      margin-bottom: 1rem; } }

.glitter_page_blocktype_bannerblock {
  display: flex;
  flex-flow: row wrap; }
  @media all and (min-width: 941px) {
    .glitter_page_blocktype_bannerblock {
      flex-flow: row nowrap; } }

.banner {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  flex-grow: 10;
  flex-shrink: 0; }
  @media all and (min-width: 641px) {
    .banner {
      position: relative;
      width: 50%; } }
  @media all and (min-width: 641px) and (max-width: 940px) {
    .banner:nth-of-type(2n):before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      border-left: 1px dashed #dadada; } }
  @media all and (min-width: 941px) {
    .banner {
      width: 25%; }
      .banner:nth-of-type(n+2):before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        border-left: 1px dashed #dadada; } }

.banner__image {
  height: 10rem; }

.banner__content {
  padding: 1.5rem;
  color: #007F70;
  background: #fff;
  flex: 1; }
  .banner__content p {
    text-decoration: underline;
    margin: 0;
    font-weight: normal;
    font-size: 1.125rem; }
  .banner__content p:last-of-type {
    color: #4A4A4A;
    margin-top: 0.25rem;
    font-size: 1rem; }

.glitter_page_blocktype_iconbannerblock {
  background: #007F70; }

.icon__banner-holder {
  display: flex;
  flex-flow: row wrap;
  max-width: 75rem;
  margin: auto;
  padding: 4rem 1.5rem; }
  @media all and (min-width: 961px) {
    .icon__banner-holder {
      flex-flow: row nowrap; } }

.icon__banner {
  display: block;
  width: 100%;
  padding: 2rem;
  flex-grow: 10;
  text-align: center;
  color: #fff;
  text-decoration: none;
  text-shadow: 2px 2px rgba(74, 74, 74, 0.25);
  position: relative; }
  .icon__banner > .icon {
    width: 6rem;
    height: 6rem;
    margin-bottom: 1.5rem; }
  .icon__banner .icon__banner-link {
    position: absolute;
    top: 6.5rem;
    left: calc(50% + 1.5rem);
    line-height: 1; }
  .icon__banner .icon__banner-title {
    text-decoration: underline; }
    .icon__banner .icon__banner-title .icon__banner-welsh {
      font-size: 1.75rem;
      margin-bottom: 0.125rem;
      font-family: "Slabo 27px"; }
    .icon__banner .icon__banner-title .icon__banner-english {
      margin-top: 0;
      font-size: 1.5rem; }
  @media all and (max-width: 640px) {
    .icon__banner:nth-of-type(n+2) {
      border-top: 1px dashed #dadada; } }
  @media all and (min-width: 641px) and (max-width: 960px) {
    .icon__banner {
      width: 50%; }
      .icon__banner:nth-of-type(2n):before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        border-left: 1px dashed #dadada; }
      .icon__banner:nth-of-type(n+3):before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        border-top: 1px dashed #dadada; } }
  @media all and (min-width: 961px) {
    .icon__banner {
      width: 25%; }
      .icon__banner:nth-of-type(n+2):before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        border-left: 1px dashed #dadada; } }

.glitter_page_blocktype_panel .panel__image {
  display: none;
  min-height: 25rem; }

.glitter_page_blocktype_panel .panel__welsh,
.glitter_page_blocktype_panel .panel__english {
  padding: 2rem;
  background: #fff;
  color: #007F70; }

.glitter_page_blocktype_panel .panel__english {
  color: #4A4A4A; }

.glitter_page_blocktype_panel .button {
  text-transform: uppercase; }

@media all and (max-width: 640px) {
  .glitter_page_blocktype_panel .panel__english {
    position: relative; }
    .glitter_page_blocktype_panel .panel__english:before {
      content: '';
      display: block;
      position: absolute;
      left: 2rem;
      right: 2rem;
      top: 0;
      border-top: 1px dashed #dadada; } }

@media all and (min-width: 641px) {
  .glitter_page_blocktype_panel > * {
    width: 50%; } }

@media all and (min-width: 641px) {
  .glitter_page_blocktype_panel {
    display: flex;
    flex-flow: row nowrap; }
    .glitter_page_blocktype_panel.align-left .panel__english, .glitter_page_blocktype_panel.align-right .panel__english {
      position: relative; }
      .glitter_page_blocktype_panel.align-left .panel__english:before, .glitter_page_blocktype_panel.align-right .panel__english:before {
        content: '';
        display: block;
        position: absolute;
        left: -1px;
        top: 1rem;
        bottom: 1rem;
        border-right: 1px dashed #dadada; } }

@media all and (min-width: 641px) and (max-width: 960px) {
  .glitter_page_blocktype_panel.align-center .panel__english {
    position: relative; }
    .glitter_page_blocktype_panel.align-center .panel__english:before {
      content: '';
      display: block;
      position: absolute;
      left: -1px;
      top: 1rem;
      bottom: 1rem;
      border-right: 1px dashed #dadada; } }

@media all and (min-width: 1361px) {
  .glitter_page_blocktype_panel > * {
    width: calc(100% / 3);
    flex-shrink: 0; }
  .glitter_page_blocktype_panel .panel__image {
    display: block; }
  .glitter_page_blocktype_panel.align-center .panel__image {
    order: 1; }
  .glitter_page_blocktype_panel.align-center .panel__english {
    order: 2; }
  .glitter_page_blocktype_panel.align-right .panel__image {
    order: 2; } }

@media all and (min-width: 961px) and (max-width: 1360px) {
  body.home .glitter_page_blocktype_panel > *,
  .page__billboard .glitter_page_blocktype_panel > * {
    width: calc(100% / 3);
    flex-shrink: 0; }
  body.home .glitter_page_blocktype_panel .panel__image,
  .page__billboard .glitter_page_blocktype_panel .panel__image {
    display: block; }
  body.home .glitter_page_blocktype_panel.align-center .panel__image,
  .page__billboard .glitter_page_blocktype_panel.align-center .panel__image {
    order: 1; }
  body.home .glitter_page_blocktype_panel.align-center .panel__english,
  .page__billboard .glitter_page_blocktype_panel.align-center .panel__english {
    order: 2; }
  body.home .glitter_page_blocktype_panel.align-right .panel__image,
  .page__billboard .glitter_page_blocktype_panel.align-right .panel__image {
    order: 2; } }

.carousel {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 100%; }
  .carousel .flickity-prev-next-button {
    border-radius: 0;
    background: #49AA98;
    width: 2.5rem;
    height: 2.5rem;
    transform: none;
    top: auto;
    bottom: 6.25rem;
    z-index: 10; }
    .carousel .flickity-prev-next-button.previous {
      left: 0; }
    .carousel .flickity-prev-next-button.next {
      right: 0; }
    .carousel .flickity-prev-next-button:disabled {
      opacity: 1; }
    .carousel .flickity-prev-next-button:active, .carousel .flickity-prev-next-button:hover {
      background: #45a190;
      opacity: 1; }
    .carousel .flickity-prev-next-button .arrow {
      fill: #fff; }

.carousel__cell-content {
  position: relative;
  z-index: 5; }

.carousel__body {
  width: 100%; }
  .carousel__body .carousel__cell-image,
  .carousel__body .carousel__cell:after {
    position: absolute;
    top: 0;
    right: -1rem;
    bottom: 0;
    left: 0; }
  .carousel__body .carousel__cell-image {
    height: 100%; }
    .carousel__body .carousel__cell-image.blurred {
      clip-path: inset(calc(100% - 6.25rem) 0 0 0); }
      @media all and (min-width: 768px) {
        .carousel__body .carousel__cell-image.blurred {
          display: block;
          clip-path: inset(calc(100% - 6.25rem) 0 0 0);
          overflow: hidden; } }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .carousel__body .carousel__cell-image.blurred {
          display: none; } }
  .carousel__body .carousel__cell-content {
    margin: 6rem 1.5rem 9.5rem 1.5rem; }
  .carousel__body .carousel__cell {
    width: 100%;
    min-height: 100%;
    color: #fff;
    position: relative;
    margin-right: 0px; }
    .carousel__body .carousel__cell.is-selected {
      z-index: 10; }
    .carousel__body .carousel__cell:after {
      content: '';
      display: block;
      background: linear-gradient(45deg, rgba(0, 0, 0, 0.625) 0%, rgba(0, 0, 0, 0.3) 100%); }

@supports (-ms-ime-align: auto) {
  .carousel__body .carousel__cell-image.blurred {
    display: none; } }

.carousel__nav {
  bottom: 0;
  color: #fff;
  background: rgba(73, 170, 152, 0.5); }
  .carousel__nav .flickity-prev-next-button {
    top: -1.375rem;
    transform: none;
    display: none; }
  .carousel__nav.flickity-enabled {
    position: absolute;
    z-index: 5;
    width: 100%; }
  .carousel__nav .carousel__cell {
    padding: 1rem 1rem 2rem 1rem;
    cursor: pointer;
    min-height: 6.25rem;
    position: relative;
    width: 75%;
    overflow: hidden; }
    .carousel__nav .carousel__cell + .carousel__cell:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      border-left: 1px dashed #dadada;
      z-index: 20; }
    .carousel__nav .carousel__cell.is-nav-selected {
      background: rgba(255, 255, 255, 0.75);
      color: #007F70; }
    @media all and (min-width: 641px) {
      .carousel__nav .carousel__cell {
        width: 50%; } }
    @media all and (min-width: 961px) {
      .carousel__nav .carousel__cell {
        width: calc(100% / 3); } }
  .carousel__nav .carousel__title {
    display: flex;
    align-items: flex-start; }
    .carousel__nav .carousel__title .carousel__title-text {
      width: 100%;
      min-width: 0;
      flex: 1;
      overflow: hidden; }
    .carousel__nav .carousel__title .icon {
      width: 2rem;
      height: 2rem;
      margin-right: 1rem; }
    .carousel__nav .carousel__title p {
      font-size: 1rem;
      text-decoration: underline;
      margin: 0;
      line-height: 1.125;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      overflow: hidden; }
      .carousel__nav .carousel__title p.english {
        font-size: 0.93rem;
        margin-top: 0.5em; }
      @media all and (min-width: 961px) {
        .carousel__nav .carousel__title p {
          font-size: 1.125rem; }
          .carousel__nav .carousel__title p.english {
            font-size: 1rem; } }

.carousel__title {
  text-shadow: 1px 1px rgba(0, 127, 112, 0.125);
  max-width: 42.5rem; }
  .carousel__title h2 {
    margin: 0; }
  .carousel__title h3 {
    margin: 0 0 2rem 0;
    font-family: "Open Sans";
    font-size: 1.75rem; }
  @media all and (max-width: 767px) {
    .carousel__title h2 {
      font-size: 1.5rem; }
    .carousel__title h3 {
      font-size: 1.125rem;
      margin-top: 0.5rem; } }
  .carousel__nav .carousel__title h4 {
    margin-bottom: 0; }

.glitter_page_blocktype_calltoaction .calltoaction__image,
.glitter_page_blocktype_coredocumentsblock .calltoaction__image {
  min-height: 17.5rem; }

.glitter_page_blocktype_calltoaction .calltoaction__body,
.glitter_page_blocktype_coredocumentsblock .calltoaction__body {
  background: #fff;
  padding: 2rem; }

.glitter_page_blocktype_calltoaction .calltoaction__list,
.glitter_page_blocktype_coredocumentsblock .calltoaction__list {
  margin: 1rem 0 2rem 0; }

.glitter_page_blocktype_calltoaction .calltoaction__title,
.glitter_page_blocktype_coredocumentsblock .calltoaction__title {
  display: flex;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px dashed #dadada; }
  .glitter_page_blocktype_calltoaction .calltoaction__title .icon,
  .glitter_page_blocktype_coredocumentsblock .calltoaction__title .icon {
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
    color: #007F70; }
  .glitter_page_blocktype_calltoaction .calltoaction__title h4,
  .glitter_page_blocktype_coredocumentsblock .calltoaction__title h4 {
    color: #007F70;
    margin-bottom: 0; }
  .glitter_page_blocktype_calltoaction .calltoaction__title p,
  .glitter_page_blocktype_coredocumentsblock .calltoaction__title p {
    margin: 0.25rem 0 0 0;
    color: #4A4A4A;
    font-size: 1.075rem; }

.glitter_page_blocktype_calltoaction .welsh__content,
.glitter_page_blocktype_coredocumentsblock .welsh__content {
  color: #007F70;
  margin-bottom: 0.5rem; }

.glitter_page_blocktype_calltoaction .english__content,
.glitter_page_blocktype_coredocumentsblock .english__content {
  color: #4A4A4A;
  margin-bottom: 1.5rem; }

@media all and (min-width: 641px) {
  .glitter_page_blocktype_calltoaction,
  .glitter_page_blocktype_coredocumentsblock {
    display: flex;
    flex-flow: row nowrap; }
    .glitter_page_blocktype_calltoaction > *,
    .glitter_page_blocktype_coredocumentsblock > * {
      width: 50%; }
    .glitter_page_blocktype_calltoaction.align-right .calltoaction__body,
    .glitter_page_blocktype_coredocumentsblock.align-right .calltoaction__body {
      order: -1; } }

@media all and (min-width: 1361px) {
  .glitter_page_blocktype_calltoaction .calltoaction__body,
  .glitter_page_blocktype_coredocumentsblock .calltoaction__body {
    width: calc(100% / 3);
    flex-shrink: 0; }
  .glitter_page_blocktype_calltoaction .calltoaction__image,
  .glitter_page_blocktype_coredocumentsblock .calltoaction__image {
    width: 100%; } }

@media all and (min-width: 961px) {
  body.home .glitter_page_blocktype_calltoaction .calltoaction__body,
  .page__billboard .glitter_page_blocktype_calltoaction .calltoaction__body, body.home
  .glitter_page_blocktype_coredocumentsblock .calltoaction__body,
  .page__billboard
  .glitter_page_blocktype_coredocumentsblock .calltoaction__body {
    width: calc(100% / 3);
    flex-shrink: 0; }
  body.home .glitter_page_blocktype_calltoaction .calltoaction__image,
  .page__billboard .glitter_page_blocktype_calltoaction .calltoaction__image, body.home
  .glitter_page_blocktype_coredocumentsblock .calltoaction__image,
  .page__billboard
  .glitter_page_blocktype_coredocumentsblock .calltoaction__image {
    width: 100%; } }

@media all and (max-width: 641px) {
  .glitter_page_blocktype_coredocumentsblock .calltoaction__image {
    display: none; } }

.glitter_page_blocktype_billboard {
  color: #fff;
  padding: 4rem 2rem;
  display: flex;
  align-items: center;
  min-height: 20rem; }
  .glitter_page_blocktype_billboard .billboard__container {
    width: 100%;
    max-width: 75rem;
    margin: auto; }
  .glitter_page_blocktype_billboard .billboard__content {
    max-width: 47.5rem;
    text-shadow: 0.0625rem 0.125em 0.5rem rgba(74, 74, 74, 0.5); }
  .glitter_page_blocktype_billboard a {
    display: flex;
    flex-flow: column nowrap;
    text-decoration: underline; }
  .glitter_page_blocktype_billboard h3 {
    margin-top: 0;
    font-size: 1.75rem;
    font-family: "Open Sans"; }

.latest-carousel__cell {
  width: calc(100% - 3rem);
  position: relative;
  min-height: 100%;
  background: #fff; }
  .latest-carousel__cell:after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    border-right: 1px dashed #dadada; }

.latest-carousel__cell-image {
  width: 100%;
  height: 10rem; }

.latest-carousel__cell-body {
  padding: 1.5rem; }
  .latest-carousel__cell-body > p {
    text-decoration: underline;
    margin: 0;
    color: #4A4A4A;
    padding-right: 1.5rem; }
    .latest-carousel__cell-body > p:first-child {
      color: #007F70;
      font-size: 1.125rem; }

.latest-carousel__details {
  margin-top: 0.75rem;
  font-size: 0.75rem; }
  .latest-carousel__details p {
    margin: 0; }

.latest-carousel .flickity-prev-next-button {
  top: 8.75rem; }

.latest-carousel .flickity-prev-next-button.previous {
  display: none; }

.latest-carousel.slide-count__1 .flickity-prev-next-button {
  display: none; }

@media all and (max-width: 640px) {
  .slide-count__1 .latest-carousel__cell {
    width: 100%; } }

@media all and (min-width: 641px) {
  .latest-carousel__cell {
    width: 40%; }
  .slide-count__1 .flickity-prev-next-button,
  .slide-count__2 .flickity-prev-next-button {
    display: none; } }

@media all and (min-width: 961px) {
  .latest-carousel__cell {
    width: 25%; }
  .latest-carousel .flickity-prev-next-button {
    display: none; }
  #glitter_column_footer .latest-carousel__cell {
    width: 50%; } }

@media all and (min-width: 1201px) {
  #glitter_column_footer .latest-carousel__cell {
    width: 33.33333%; } }

.glitter_page_blocktype_personblock .list__item-details p a {
  margin-right: 1rem; }

.glitter_page_blocktype_personblock .list__item-body h3 {
  text-decoration: underline; }

.glitter_page_blocktype_redactor blockquote {
  margin: 1rem 0;
  padding: 1rem 0 1rem 3rem;
  position: relative;
  font-size: 1.75rem;
  line-height: 1.25;
  font-weight: 300; }
  .glitter_page_blocktype_redactor blockquote:before {
    content: '{';
    display: inline-block;
    font-size: 4.5rem;
    position: absolute;
    top: 0;
    left: 0;
    color: #dadada;
    font-weight: 300; }

.glitter_page_blocktype_redactor a {
  font-family: "Slabo 27px";
  text-decoration: underline;
  font-size: 1.125em; }

.glitter_page_blocktype_masonryblock {
  margin: 3rem auto; }
  .glitter_page_blocktype_masonryblock .grid {
    margin: 0 auto; }
    .glitter_page_blocktype_masonryblock .grid .grid-item {
      margin-bottom: 1rem;
      width: 100%; }
    .glitter_page_blocktype_masonryblock .grid .grid-sizer {
      width: 45%; }
      @media screen and (min-width: 768px) {
        .glitter_page_blocktype_masonryblock .grid .grid-sizer {
          width: 24%; }
          .glitter_page_blocktype_masonryblock .grid .grid-sizer.grid-length--4 {
            width: 30%; }
          .glitter_page_blocktype_masonryblock .grid .grid-sizer.grid-length--10 {
            width: 18%; } }
  .glitter_page_blocktype_masonryblock .css-masonry-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, 45%);
    grid-template-rows: masonry;
    grid-gap: 1rem;
    justify-content: center; }
    @media screen and (min-width: 768px) {
      .glitter_page_blocktype_masonryblock .css-masonry-grid {
        grid-template-columns: repeat(auto-fit, 24%); } }

.glitter_page_block + .glitter_page_block {
  margin-top: 1rem; }

#glitter_column_footer .glitter_page_block:first-of-type {
  margin-top: 1rem; }

.icon__choices {
  display: flex;
  flex-flow: row wrap;
  padding: 1rem;
  background: #F3F3F3; }

.icon__choice {
  width: 50%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: 1rem;
  font-family: "Slabo 27px";
  font-size: 1.125rem;
  color: #4A4A4A; }
  .icon__choice .icon {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
    color: #007F70; }

.list__item-list,
.list__item-grid {
  margin-bottom: 1rem; }

.list__item-grid {
  display: flex;
  flex-flow: row wrap; }
  @media all and (min-width: 641px) and (max-width: 1360px) {
    .list__item-grid .list__item-card,
    .list__item-grid .list__item-panel {
      width: calc(50% - 0.5rem);
      margin-right: 1rem; }
      .list__item-grid .list__item-card:nth-of-type(2n),
      .list__item-grid .list__item-panel:nth-of-type(2n) {
        margin-right: 0; }
      .list__item-grid .list__item-card:nth-of-type(n+3),
      .list__item-grid .list__item-panel:nth-of-type(n+3) {
        margin-top: 1rem; } }
  @media all and (min-width: 1361px) {
    .list__item-grid .list__item-card,
    .list__item-grid .list__item-panel {
      width: calc((100% / 3) - 0.66667rem);
      margin-right: 1rem; }
      .list__item-grid .list__item-card:nth-of-type(3n),
      .list__item-grid .list__item-panel:nth-of-type(3n) {
        margin-right: 0; }
      .list__item-grid .list__item-card:nth-of-type(n+4),
      .list__item-grid .list__item-panel:nth-of-type(n+4) {
        margin-top: 1rem; } }

.list__item-card,
.list__item-panel {
  display: flex;
  width: 100%; }
  .list__item-card + .list__item-panel,
  .list__item-panel + .list__item-panel {
    margin-top: 1rem; }
  .list__item-card .list__item-welsh,
  .list__item-card .list__item-english,
  .list__item-panel .list__item-welsh,
  .list__item-panel .list__item-english {
    padding: 1.5rem;
    width: 100%; }
  .list__item-card .list__item-welsh,
  .list__item-card .list__item-details,
  .list__item-panel .list__item-welsh,
  .list__item-panel .list__item-details {
    color: #007F70; }
  .list__item-card .list__item-english,
  .list__item-panel .list__item-english {
    padding-top: 0;
    color: #4A4A4A; }
  .list__item-card .list__item-external,
  .list__item-panel .list__item-external {
    display: inline-flex;
    align-items: center;
    position: absolute;
    padding: 0 0.5rem;
    top: 0;
    right: 0;
    min-width: 0;
    max-width: 2rem;
    height: 2rem;
    background: #B45B5B;
    color: #fff;
    font-family: "Slabo 27px";
    box-shadow: 0.05rem 0.05rem 0.5rem 0 rgba(74, 74, 74, 0.5);
    transition: 0.2s max-width ease-in;
    will-change: max-width;
    user-select: none; }
    .list__item-card .list__item-external .icon,
    .list__item-panel .list__item-external .icon {
      flex-shrink: 0;
      width: 1.125rem;
      height: 1.125rem;
      margin-right: 0.75rem; }
    .list__item-card .list__item-external span,
    .list__item-panel .list__item-external span {
      opacity: 0;
      transition: 0.2s opacity; }
  .list__item-card:hover .list__item-external,
  .list__item-panel:hover .list__item-external {
    max-width: 100%; }
    .list__item-card:hover .list__item-external span,
    .list__item-panel:hover .list__item-external span {
      opacity: 1; }

.list__item-card {
  flex-flow: column nowrap; }
  .list__item-card .list__item-body {
    flex-flow: column nowrap;
    flex-grow: 10; }

.list__item-panel {
  flex-flow: column nowrap; }
  .search__results .list__item-panel + .list__item-panel {
    border-top: 1px dashed #dadada; }
  .search__results .list__item-panel .list__item-details,
  .search__results .list__item-panel .list__item-welsh,
  .search__results .list__item-panel .list__item-english {
    padding: 1rem 0; }
  .search__results .list__item-panel .list__item-welsh {
    padding-bottom: 0; }
  .search__results .list__item-panel .list__item-english {
    padding-top: 0; }
  .search__results .list__item-panel .list__item-details {
    padding-bottom: 0;
    color: #4A4A4A; }
    .search__results .list__item-panel .list__item-details > span {
      margin-right: 0.675rem; }
    .search__results .list__item-panel .list__item-details:after {
      content: none; }
  @media all and (min-width: 641px) {
    .list__item-panel {
      flex-flow: row nowrap; }
      .list__item-panel .list__item-body {
        width: calc(200% /3);
        flex-grow: 10; }
      .list__item-panel .list__item-image {
        width: calc(100% /3);
        flex-shrink: 0; }
      .list__item-panel .list__item-welsh,
      .list__item-panel .list__item-english {
        width: 50%;
        padding: 1.5rem;
        position: relative; }
      .list__item-panel .list__item-english:before {
        content: '';
        display: block;
        position: absolute;
        top: 1rem;
        bottom: 1rem;
        left: 0;
        border-left: 1px dashed #dadada; }
      .search__results .list__item-panel .list__item-details,
      .search__results .list__item-panel .list__item-welsh,
      .search__results .list__item-panel .list__item-english {
        padding: 1rem 0; }
      .search__results .list__item-panel .list__item-welsh {
        padding-right: 1.5rem; }
      .search__results .list__item-panel .list__item-english {
        padding-left: 1.5rem; } }

.list__item-body {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  background: #fff; }

.list__item-details {
  padding: 1.5rem;
  position: relative;
  width: 100%; }
  .list__item-details a {
    text-decoration: underline;
    font-family: "Slabo 27px";
    font-size: 1.125rem; }
    .list__item-details a.button, .list__item-details a.button__multilingual, .list__item-details .page__holder form a.form-button__multilingual, .page__holder form .list__item-details a.form-button__multilingual, .list__item-details
    .dynamic-form a.form-button__multilingual,
    .dynamic-form .list__item-details a.form-button__multilingual {
      text-decoration: none; }
  .list__item-details p {
    margin: 0.25rem 0; }
    .list__item-details p:first-child {
      margin-top: 0; }
    .list__item-details p:last-child {
      margin-bottom: 0; }
  .list__item-details:first-child:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 1.5rem;
    right: 1.5rem;
    border-bottom: 1px dashed #dadada; }
  .list__item-details:last-child:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 1.5rem;
    right: 1.5rem;
    border-top: 1px dashed #dadada; }
  .list__item-details .list__item-date {
    margin-bottom: 0; }
    .list__item-details .list__item-date + p {
      margin-top: 0.25rem; }

.list__item-image {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  min-height: 12.5rem;
  flex-shrink: 0;
  position: relative;
  overflow: hidden; }
  .list__item-image .list__item-meta-wrapper {
    display: flex;
    margin-top: auto;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%; }
    .list__item-image .list__item-meta-wrapper .download__button {
      margin-left: 1rem;
      background: #49AA98; }
      .list__item-image .list__item-meta-wrapper .download__button:hover {
        background: #45a190; }
  .list__item-image .list__item-meta {
    display: inline-block;
    margin-top: auto;
    background: rgba(0, 95, 84, 0.875);
    padding: 0.5rem 1rem;
    color: #fff;
    max-width: 17.5rem; }
    .list__item-image .list__item-meta div {
      font-size: 0.875rem; }

.list__item-english .list__item-title,
.list__item-english h3 {
  font-size: 1.35rem;
  line-height: 2.2rem; }

.list__item-english h3 {
  margin-bottom: 0.175em; }

.list__item-english .list__item-title {
  margin-bottom: 0.325em; }

.list__item-title {
  margin-bottom: 0.25rem;
  text-decoration: underline; }
  .list__item-english .list__item-title {
    font-size: 1.35rem;
    line-height: 2.2rem; }
  .list__item-title + * {
    margin-top: 0; }

.document__details {
  display: flex;
  width: 100%;
  flex-flow: column nowrap;
  margin-bottom: 1rem; }
  @media all and (min-width: 768px) {
    .document__details {
      flex-flow: row nowrap; }
      .document__details .document__meta,
      .document__details .document__image {
        width: calc(50% - 0.5rem);
        flex-grow: 1; }
      .document__details .document__image + .document__meta {
        margin-left: 1rem; } }

.document__image {
  min-height: 10rem; }

.document__social {
  position: absolute;
  bottom: 0;
  right: 0; }

.document__link {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start; }
  .document__link .button {
    margin-left: 1rem;
    flex-shrink: 0; }
  .document__link .document__link-title {
    color: #007F70; }
    .document__link .document__link-title span {
      display: block;
      text-decoration: underline; }
    .document__link .document__link-title .english {
      color: #4A4A4A; }
    .document__link .document__link-title.not__link span {
      text-decoration: none; }
  .document__link + .document__link {
    padding-top: 0.75rem;
    margin-top: 0.75rem;
    border-top: 1px dashed #dadada; }

.document__meta {
  padding: 3.5rem 1.5rem 4rem;
  background: #fff;
  position: relative; }
  .document__meta .page__split-link {
    position: absolute;
    top: 0;
    left: 0; }
  @media all and (min-width: 641px) {
    .document__meta {
      padding-top: 1.5rem; } }

.document__meta-title {
  width: calc(100% - 2rem);
  margin-bottom: 1rem;
  color: #007F70; }
  .document__meta-title > * {
    text-decoration: underline;
    margin: 0; }
  .document__meta-title .english {
    color: #4A4A4A;
    font-size: 1.25rem; }

.document__download {
  position: absolute;
  top: 0;
  right: 0; }

.download-button {
  padding: 0.25rem 0.5rem;
  background: #49AA98;
  color: #fff; }
  .download-button .icon {
    vertical-align: middle; }
  .download-button:hover {
    background: #45a190; }

.article {
  background: #fff;
  overflow: hidden; }
  .article .page__split {
    padding: 0; }

.article__social {
  font-size: 1rem;
  color: #49AA98;
  margin-left: auto; }
  @media all and (min-width: 641px) {
    .article__social {
      margin-left: 1rem; } }

.article__image {
  height: 15rem; }
  @media all and (min-width: 961px) {
    .article__image {
      height: 25rem; } }

.article__meta-bar {
  display: flex;
  flex-flow: row wrap;
  padding: 0.5rem 1.5rem;
  justify-content: space-between;
  align-items: center;
  font-size: 0.75rem;
  position: relative; }
  .article__meta-bar:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 1.5rem;
    right: 1.5rem;
    border-top: 1px dashed #dadada; }
  .article__meta-bar .article__meta {
    width: 100%;
    order: 3;
    margin: 1rem 0 0 auto; }
    .article__meta-bar .article__meta span {
      margin-right: 0.75em; }
    .article__meta-bar .article__meta .article__meta-span--welsh {
      margin-right: 0; }
    .article__meta-bar .article__meta a {
      text-decoration: underline; }
    @media all and (min-width: 641px) {
      .article__meta-bar .article__meta {
        margin: 0 0 0 0; } }
  .article__meta-bar .social {
    color: #007F70; }
  .article__meta-bar .page__split-link {
    margin: -0.5rem 0 0 -1.5rem; }
  @media all and (min-width: 641px) {
    .article__meta-bar {
      flex-flow: row nowrap; }
      .article__meta-bar .article__meta {
        order: initial; } }

.search__results {
  padding: 1.5rem;
  background: #fff; }

.search__results-title {
  color: #005F54; }

.sidebar__search-form .form-button__multilingual {
  margin-top: 1rem !important; }

.event__details {
  margin-bottom: 1rem; }

.vacancy__details {
  margin-bottom: 1rem; }

.meta__list {
  list-style: none;
  padding: 0;
  margin: 0; }
  .meta__list li + li {
    padding-top: 0.75rem;
    margin-top: 0.75rem;
    border-top: 1px dashed #dadada; }

html,
body {
  width: 100%;
  overflow-x: hidden; }

.page__holder {
  display: flex;
  flex-flow: row nowrap;
  transition: left 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  will-change: left;
  position: relative;
  left: -100%;
  min-height: 100vh;
  max-height: 100vh; }
  @media all and (min-width: 641px) {
    .page__holder {
      left: -25rem; } }
  .menu__open .page__holder {
    left: 0; }

.page__content {
  width: 100%;
  min-height: 100%;
  flex-shrink: 0;
  background: #F3F3F3;
  position: relative;
  overflow: auto; }

.page__container {
  max-width: 75rem;
  margin: auto; }
  .page__container #glitter_column_billboard .glitter_page_block:last-of-type {
    margin-bottom: 1rem; }
  .page__container #glitter_column_billboard .glitter_page_blocktype_imageblock:last-of-type,
  .page__container #glitter_column_billboard .glitter_page_blocktype_carouselblock:last-of-type {
    margin-bottom: 0; }

body:not(.home) .page__content-wrapper > main {
  max-width: 75rem;
  margin: auto; }

.page__content-wrapper {
  min-height: 100%; }
  @media all and (min-width: 641px) {
    .page__content-wrapper {
      position: relative;
      padding-left: 1rem; }
      .page__content-wrapper:before {
        content: '';
        display: block;
        background: #007F70;
        position: absolute;
        top: 0;
        left: 0;
        min-height: 100%;
        z-index: 5;
        width: 1rem; } }

.page__billboard .glitter_page_block:last-of-type {
  margin-bottom: 1rem; }

@media all and (min-width: 641px) {
  .page__content-holder {
    padding-right: 1rem; }
    body.home .page__content-holder {
      padding-right: 0; } }

@media all and (min-width: 961px) {
  .page__content-holder {
    display: flex;
    flex-flow: row nowrap; } }

.page__content-sidebar {
  margin-bottom: 1rem;
  display: none; }
  @media all and (min-width: 961px) {
    .page__content-sidebar {
      display: block; } }

.page__split-welsh,
.page__split-english {
  padding: 1.5rem;
  position: relative; }
  .default .page__split-welsh,
  .single-col .page__split-welsh, .default
  .page__split-english,
  .single-col
  .page__split-english {
    padding-top: 4rem; }
    .default .page__split-welsh .page__split-link,
    .single-col .page__split-welsh .page__split-link, .default
    .page__split-english .page__split-link,
    .single-col
    .page__split-english .page__split-link {
      position: absolute;
      top: -1px;
      left: 0; }

.page__split-welsh {
  color: #007F70; }

.page__split-english {
  color: #4A4A4A; }

.page__split-english {
  padding-top: 4rem; }
  .page__split-english .page__split-link {
    position: absolute;
    top: -1px;
    left: 0; }
    .page__split-english .page__split-link + * {
      margin-top: 0; }
  @media all and (max-width: 640px) {
    .page__split-english {
      border-top: 1px dashed #dadada;
      margin-top: 1rem; } }

.page__split {
  background: #fff; }
  @media all and (min-width: 641px) {
    .page__split {
      display: flex;
      flex-flow: row nowrap;
      padding: 1rem; }
      .page__split .page__split-welsh,
      .page__split .page__split-english {
        width: 50%;
        padding-top: 1.5rem; }
      .page__split .page__split-english:before {
        content: '';
        display: block;
        position: absolute;
        top: 1rem;
        bottom: 1rem;
        left: 0;
        border-left: 1px dashed #dadada; } }

.page__split-link {
  text-transform: uppercase;
  font-size: 1rem; }
  @media all and (min-width: 641px) {
    .page__split-link {
      display: none; } }

@media all and (min-width: 961px) {
  .page__content-sidebar {
    width: 18.5rem;
    flex-shrink: 0;
    margin: 0 1rem 0 0; }
  .page__content-body {
    width: 100%; } }

.sidebar__nav {
  background: #fff;
  padding: 1rem; }
  .sidebar__nav .sidebar__nav-title {
    margin-bottom: 1rem;
    color: #007F70; }
    .sidebar__nav .sidebar__nav-title > * {
      margin: 0; }
    .sidebar__nav .sidebar__nav-title p {
      color: #4A4A4A;
      font-size: 1.125rem; }
  .sidebar__nav + .sidebar__nav {
    margin-top: 2rem; }

.page-footer {
  background: #005F54;
  box-shadow: 0px 100vh 0px 100vh #005F54;
  padding: 2rem 0;
  position: relative;
  color: #fff;
  margin-top: 3.5rem; }
  .page-footer > .container {
    width: calc(100% - 2rem);
    padding: 0;
    overflow: hidden; }
  .page-footer .social {
    margin: 1.5rem 0 0 0; }
  @media all and (min-width: 768px) {
    .page-footer .social {
      margin: 0 0 0 auto; } }

.page-footer__legal {
  font-size: 0.75rem; }
  .page-footer__legal a {
    text-decoration: underline; }

@media all and (min-width: 768px) {
  .page-footer__social {
    display: flex; } }

.footer__copyright {
  opacity: 0.25; }

.footer__bar {
  display: flex;
  width: 100%;
  flex-flow: column nowrap; }
  @media all and (min-width: 961px) {
    .footer__bar {
      flex-flow: row nowrap;
      align-items: center; }
      .footer__bar .footer__brand {
        margin-right: 1.5rem;
        flex-shrink: 0;
        padding-right: 1.5rem;
        border-right: 1px dashed rgba(255, 255, 255, 0.75); }
      .footer__bar .footer__section {
        width: 100%; } }

.page-footer .social a {
  color: rgba(255, 255, 255, 0.75); }
  .page-footer .social a:hover {
    color: white; }

.footer__section {
  margin-top: 2rem;
  padding: 1rem 0;
  border-top: 1px dashed rgba(255, 255, 255, 0.2); }
  .footer__section p {
    font-size: 0.75rem;
    max-width: 42.5rem; }
    .footer__section p:first-of-type {
      font-weight: bold; }
  .footer__section a {
    text-decoration: underline; }
  .footer__section .footer__section-title {
    font-size: 1rem; }
  @media all and (min-width: 961px) {
    .footer__section {
      margin-top: 0;
      border-top: none; } }

.page__nav {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  padding: 3rem 0 1rem 0;
  flex-shrink: 0;
  background: radial-gradient(at 60% bottom, #005f54, rgba(11, 97, 88, 0.6)), radial-gradient(at top left, rgba(73, 170, 152, 0.6), #005f54);
  overflow: hidden;
  color: #fff;
  justify-content: space-between; }
  @media all and (min-width: 641px) {
    .page__nav {
      width: 25rem;
      padding-top: 1rem; } }

.page__nav-header {
  display: none;
  padding: 0 1rem; }
  @media all and (min-width: 641px) {
    .page__nav-header {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      flex-shrink: 0; }
      .page__nav-header .nav__toggle {
        margin-left: auto;
        margin-right: -1rem; } }

.page__nav-footer {
  margin-top: auto;
  padding-top: 1rem;
  border-top: 1px dashed rgba(255, 255, 255, 0.2);
  flex-shrink: 0;
  padding: 1rem 1rem 0; }
  .page__nav-footer .social a {
    color: rgba(255, 255, 255, 0.75); }
    .page__nav-footer .social a:hover {
      color: #fff; }

.nav__toggle {
  position: relative;
  z-index: 10;
  cursor: pointer;
  user-select: none; }
  .page__content .nav__toggle {
    display: none;
    margin-left: -1rem;
    transition: opacity 0.5s cubic-bezier(0.23, 1, 0.32, 1);
    box-shadow: 0.05rem 0.05rem 0.5rem 0 rgba(74, 74, 74, 0.5); }
    .menu__open .page__content .nav__toggle {
      opacity: 0;
      pointer-events: none; }
    @media all and (min-width: 641px) {
      .page__content .nav__toggle {
        display: inline-block;
        margin-left: -2rem; } }

.pagination {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 1.5rem 0; }
  .pagination .button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: "Open Sans";
    min-width: 2.5rem;
    text-align: center;
    line-height: 1.5rem; }
  @media all and (min-width: 641px) {
    .pagination {
      padding-right: 1rem; }
      .page__content-body .pagination {
        padding-right: 0; } }

.pagination__inner {
  display: inline-flex;
  margin: 0 0.25rem; }
  .pagination__inner .button {
    margin: 0 0.25rem; }

.icon__link {
  display: inline-flex;
  align-items: flex-start;
  line-height: 1;
  width: 100%; }
  .icon__link .icon {
    width: 2.5rem;
    height: 2.5rem;
    display: inline-block;
    margin-right: 0.875rem;
    flex-shrink: 0; }
  .icon__link .icon__link-text {
    display: flex;
    flex-flow: column nowrap;
    font-weight: 600;
    align-items: flex-start; }
    .icon__link .icon__link-text .english {
      display: inline;
      font-family: "Open Sans";
      opacity: 0.75;
      font-weight: normal;
      margin-top: 0.25rem; }

.site__navigation {
  display: none;
  flex-flow: row nowrap;
  font-size: 0.75rem; }
  .site__navigation .icon {
    color: #007F70;
    flex-shrink: 0; }
  .site__navigation .icon__link {
    overflow: inherit; }
  .site__navigation .icon__link-text {
    color: #007F70; }
    .site__navigation .icon__link-text span {
      text-decoration: underline;
      white-space: nowrap;
      display: block; }
    .site__navigation .icon__link-text .english {
      color: #4A4A4A; }
  @media all and (min-width: 641px) {
    .site__navigation {
      display: flex; } }

.pebbletrail__navigation {
  display: none; }
  .pebbletrail__navigation .icon__link + .icon__link {
    padding-left: 1rem;
    margin-left: 1rem;
    position: relative; }
    .pebbletrail__navigation .icon__link + .icon__link .icon {
      display: none; }
    .pebbletrail__navigation .icon__link + .icon__link:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      border-left: 1px solid #dadada; }
    .pebbletrail__navigation .icon__link + .icon__link:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: calc(-0.25rem - 2px);
      width: 0.5rem;
      height: 0.5rem;
      border-width: 1px;
      border-style: solid;
      border-color: #dadada #dadada transparent transparent;
      transform: translateY(-50%) rotate(45deg);
      transform-origin: center center;
      background: #F3F3F3; }
  @media all and (min-width: 768px) {
    .pebbletrail__navigation {
      display: flex;
      flex-flow: row nowrap; } }

.nav__primary-holder {
  position: relative;
  height: 100%;
  flex-shrink: 10;
  overflow: hidden;
  padding: 0 1rem; }
  .nav__primary-holder:after {
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1rem;
    right: -1rem;
    width: calc(100% + 2rem);
    pointer-events: none;
    user-select: none;
    box-shadow: inset 0px 0px 1rem 0.5rem #005F54;
    z-index: 5; }

.nav__primary-inner {
  position: relative;
  margin-right: -17px;
  padding-right: 17px;
  height: 100%;
  overflow: auto; }
  .nav__primary-inner::-webkit-scrollbar {
    display: none; }

.nav__primary {
  display: flex;
  flex-flow: column nowrap; }
  .nav__primary > .nav__item:first-of-type {
    margin-top: 1rem; }
  .nav__primary > .nav__item:last-of-type {
    margin-bottom: 1rem; }
  .nav__primary .nav__item + .nav__item {
    margin-top: 0.75rem;
    padding-top: 0.75rem;
    border-top: 2px dotted rgba(255, 255, 255, 0.2); }
  .nav__primary .nested__nav {
    padding-left: 1.5rem;
    margin-top: 0.75rem;
    padding-top: 0.75rem;
    border-top: 2px dotted rgba(255, 255, 255, 0.2); }

.nav__list {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 0.875rem; }
  .nav__list .nested__nav {
    width: 100%;
    padding: 0.75rem 0 0 1.5rem;
    margin-top: 0.75rem;
    border-top: 1px dashed #dadada; }
  .nav__list li {
    display: flex; }
    .nav__list li a > .icon {
      display: none;
      width: 1.15em;
      height: 1.5em; }
    .nav__list li.active > a {
      font-weight: 600; }
      .nav__list li.active > a > .icon {
        display: block;
        margin-right: 0.5rem; }
    .nav__list li.open > a {
      font-weight: 600; }
    .nav__list li.with__child {
      flex-flow: row wrap; }
      .nav__list li.with__child a {
        width: 100%; }
    .nav__list li .nav__list-link {
      display: flex;
      flex-flow: row nowrap;
      color: #007F70; }
      .nav__list li .nav__list-link > div {
        text-decoration: underline; }
        .nav__list li .nav__list-link > div span:lang(en-GB) {
          display: block;
          color: #4A4A4A; }
      .nav__list li .nav__list-link > div.nav__list-link--span-styling-override {
        text-decoration: underline; }
        .nav__list li .nav__list-link > div.nav__list-link--span-styling-override span {
          display: block;
          color: #4A4A4A; }
    .nav__list li + li {
      padding-top: 0.75rem;
      margin-top: 0.75rem;
      border-top: 1px dashed #dadada; }

.footer__nav {
  display: none;
  margin: 1rem 0;
  flex-flow: row wrap;
  width: calc(100% + 2rem);
  margin-left: -1rem;
  padding: 1rem 0;
  border-top: 1px dashed rgba(255, 255, 255, 0.75);
  border-bottom: 1px dashed rgba(255, 255, 255, 0.75); }
  @media all and (min-width: 768px) {
    .footer__nav {
      display: flex; } }
  .footer__nav > .footer__nav-item {
    flex-shrink: 0;
    width: calc(100% / 3);
    border-right: 1px dashed rgba(255, 255, 255, 0.75); }
    @media all and (min-width: 768px) and (max-width: 1080px) {
      .footer__nav > .footer__nav-item:nth-of-type(n+4) {
        border-top: 1px dashed rgba(255, 255, 255, 0.75); }
        .footer__nav > .footer__nav-item:nth-of-type(n+4) .footer__link {
          padding-top: 1rem; } }
    @media all and (min-width: 1081px) {
      .footer__nav > .footer__nav-item {
        width: calc(100% / 5); }
        .footer__nav > .footer__nav-item:nth-of-type(n+6) {
          border-top: 1px dashed rgba(255, 255, 255, 0.75); }
          .footer__nav > .footer__nav-item:nth-of-type(n+6) .footer__link {
            padding-top: 1rem; } }

.footer__link {
  padding: 0 1rem 1rem 1rem;
  display: block; }
  .footer__link span {
    display: block; }
  .footer__link .english {
    font-weight: 300; }

.footer__nested {
  border-top: 1px dashed rgba(255, 255, 255, 0.15); }
  .footer__nested .footer__link {
    padding: 1rem; }
  .footer__nested .footer__nav-item + .footer__nav-item .footer__link {
    padding-top: 0; }

.nested__nav {
  display: none; }
  .open > .nested__nav {
    display: block; }

.nested__nav-toggle {
  margin-left: auto;
  padding-left: 1rem;
  align-self: center; }
  .nested__nav-toggle .icon {
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle; }
  .nested__nav-toggle .toggle-shut {
    display: none; }
  .open > a .nested__nav-toggle .toggle-open {
    display: none; }
  .open > a .nested__nav-toggle .toggle-shut {
    display: initial; }

body.home .site__navigation .icon__link {
  display: none; }

body.home .page__footer {
  margin-top: 0; }

body.home #glitter_column_content .glitter_page_block:not(.glitter_page_blocktype_carouselblock):not(.glitter_page_blocktype_billboard):not(.glitter_page_blocktype_iconbannerblock) {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto; }

@media all and (min-width: 641px) {
  body.home .page__buttons {
    padding-right: 1rem; } }

body.home.white-logo .page__header {
  margin-bottom: 0;
  padding: 0; }
  @media all and (min-width: 641px) {
    body.home.white-logo .page__header {
      padding: 1rem; } }

body.home.white-logo .page__header-top .page__logo {
  filter: brightness(0) invert(1); }

body.home.white-logo .page__header-top .search__form input {
  color: #fff; }

body.home.white-logo .page__billboard .carousel {
  margin-top: -2.715rem; }
  body.home.white-logo .page__billboard .carousel .carousel__body .carousel__cell .carousel__cell-content {
    margin-top: 5rem; }
  @media all and (min-width: 641px) {
    body.home.white-logo .page__billboard .carousel {
      margin-top: -9.125rem; }
      body.home.white-logo .page__billboard .carousel .carousel__body .carousel__cell .carousel__cell-content {
        margin-top: 11.5rem; } }
  @media all and (min-width: 961px) {
    body.home.white-logo .page__billboard .carousel .carousel__body .carousel__cell .carousel__cell-content {
      margin-top: 13rem; } }

body.home.white-logo .page__billboard .glitter-toolbar + .carousel {
  margin-top: 0; }

.mobile__nav-header {
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 95, 84, 0.75);
  z-index: 10; }
  .mobile__nav-header .close {
    display: none; }
  .menu__open .mobile__nav-header .open {
    display: none; }
  .menu__open .mobile__nav-header .close {
    display: inline-flex; }
  .mobile__nav-header .page__logo {
    background-size: 75%;
    background-position: right 0.5rem center;
    padding: 0.25rem 0; }
  @media all and (min-width: 641px) {
    .mobile__nav-header {
      display: none; } }

.page__header {
  position: relative;
  padding: 1rem;
  z-index: 15;
  margin-top: 2.715rem; }
  .page__header .custom__select-holder {
    display: inline-block;
    width: 100%;
    min-width: 16.25rem; }
  @media all and (min-width: 641px) {
    .page__header {
      padding: 1rem;
      margin-top: 0; }
      .page__header .custom__select-holder {
        margin-top: 1rem;
        margin-left: 0.75rem;
        width: auto; }
      .page__header.sticky {
        min-height: 9.1875rem; }
        .page__header.sticky .nav__toggle {
          position: fixed;
          top: 1rem;
          left: 0;
          margin-left: 0; }
        .page__header.sticky .custom__select-holder {
          margin-left: 7.2rem; } }
  @media all and (min-width: 961px) {
    .page__header .custom__select-holder {
      display: none; } }

.page__header-top {
  display: none;
  margin-bottom: 1rem; }
  @media all and (min-width: 641px) {
    .page__header-top {
      display: flex;
      flex-flow: row nowrap;
      align-items: center; }
      .page__header-top .site__navigation {
        margin-left: auto; }
      .page__header-top .custom__select-holder {
        width: auto; } }

#glitter-versionlist {
  border-collapse: collapse; }

#glitter-menu ~ .page__holder {
  max-height: none;
  background: #005F54; }
