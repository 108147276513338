.custom__select-holder {
    position: relative;

    &:focus { outline: none; }
}

.custom__select-search {
    .custom__option,
    .custom__select-toggle {
        font-size: 1.125rem;
        font-family: $base-headline-font;
        color: $color-text-welsh;

        span {
            font-size: 1rem;
            font-family: $base-font;
            margin-left: 0.25rem;
            color: $color-text-english;
        }

        .icon { font-size: 1rem; }
    }
}

.custom__select-dropdown {
    display: none;
    background: #fff;
    border-top: 0.125rem solid $color-primary;
    position: absolute;
    top: 100%;
    min-width: 100%;
    z-index: 20;
    max-height: 13rem;
    overflow: auto;
    box-shadow: 0px 0px $width-gap*0.75 0px rgba($color-text-english, 0.2);

    .custom__select-open + & {
        display: block;
    }
}

.custom__option,
.custom__select-toggle {
    color: $color-text-english;
    padding: $width-gap/2;
    cursor: pointer;

    span {
        font-size: 1.125rem;
        font-family: $base-headline-font;
        color: $color-text-welsh;
        margin-right: 0.25em;
    }

    + .custom__option {
        border-top: 1px dashed $color-line;
    }

    &:hover {
        background: rgba($color-primary, 0.0625);
    }
}

.custom__select-toggle {
    background: #fff;
    display: flex;
    justify-content: space-between;

    .icon {
        margin-left: $width-gap;
        color: $color-tertiary;
    }

    .toggle-close { display: none; }

    &.custom__select-open {

        .toggle-open { display: none; }
        .toggle-close { display: block; }
    }

    &:focus { outline: none; }
}
