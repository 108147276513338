.glitter_page_blocktype_redactor {

    blockquote {
        margin: 1rem 0;
        padding: 1rem 0 1rem 3rem;
        position: relative;
        font-size: 1.75rem;
        line-height: 1.25;
        font-weight: 300;

        &:before {
            content: '{';
            display: inline-block;
            font-size: 4.5rem;
            position: absolute;
            top: 0;
            left: 0;
            color: $color-line;
            font-weight: 300;
        }
    }

    a {
        font-family: $base-headline-font;
        text-decoration: underline;
        font-size: 1.125em;
    }
}
