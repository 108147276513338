@import 'textimage';
@import 'banner';
@import 'iconbanner';
@import 'panel';
@import 'carousel';
@import 'calltoaction';
@import 'billboard';
@import 'latestcarousel';
@import 'person';
@import 'redactor';
@import 'masonry';

.glitter_page_block + .glitter_page_block {
    margin-top: $width-gap;
}

#glitter_column_footer .glitter_page_block:first-of-type {
    margin-top: $width-gap;
}


.icon__choices {
    display: flex;
    flex-flow: row wrap;
    padding: $width-gap;
    background: $color-background;
}

.icon__choice {
    width: 50%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: $width-gap;
    font-family: $base-headline-font;
    font-size: 1.125rem;
    color: $color-text-english;

    .icon {
        width: 2.5rem;
        height: 2.5rem;
        margin-right: $width-gap;
        color: $color-text-welsh;
    }
}
