.page__logo {
    display: inline-block;
    height: $height-logo;
    vertical-align: top;
    flex-shrink: 0;

    img {
        width: auto;
        height: 100%;
    }

    &.inverted {
        filter: brightness(0) invert(1);
    }

    &.small {
        height: $height-logo*0.75;
    }
}

.page__crests {
    display: inline-block;
    width: 8rem;
    height: 5rem;
    background: url(../img/crests.svg) center center/contain no-repeat;
}
