.social {
    @extend %reset-list;

    display: flex;
    flex-flow: row nowrap;

    > li + li { margin-left: $width-gap; }

    &.social-buttons {
        li {

            a {
                display: inline-block;
                background: $color-tertiary;
                color: #fff;
                padding: 0.5rem;
                line-height: 1;

                &:hover { background: darken($color-tertiary, 2.5%); }
            }

            + li { margin-left: $width-gap*0.75; }
        }
    }
}
