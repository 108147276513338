.glitter_page_blocktype_iconbannerblock {
    background: $color-tertiary;
}

.icon__banner-holder {
    display: flex;
    flex-flow: row wrap;
    max-width: 75rem;
    margin: auto;
    padding: $width-gap*4 $width-gap*1.5;

    @media all and (min-width: 961px) {
        flex-flow: row nowrap;
    }
}

.icon__banner {
    display: block;
    width: 100%;
    padding: $width-gap*2;
    flex-grow: 10;
    text-align: center;
    color: #fff;
    text-decoration: none;
    text-shadow: 2px 2px rgba($color-text-english, 0.25);
    position: relative;

    > .icon {
        width: 6rem;
        height: 6rem;
        margin-bottom: $width-gap*1.5;
    }

    .icon__banner-link {
        position: absolute;
        top: 6.5rem;
        left: calc(50% + 1.5rem);
        line-height: 1;

    }

    .icon__banner-title {
        text-decoration: underline;

        .icon__banner-welsh {
            font-size: 1.75rem;
            margin-bottom: 0.125rem;
            font-family: $base-headline-font;
        }
        .icon__banner-english {
            margin-top: 0;
            font-size: 1.5rem;
        }
    }

    @media all and (max-width: 640px) {
        &:nth-of-type(n+2) {
            border-top: 1px dashed $color-line;
        }
    }

    @media all and (min-width: 641px) and (max-width: 960px) {
        width: 50%;

        &:nth-of-type(2n):before {
            content: '';
            display: block;
            position: absolute;
            top: 0; bottom: 0; left: 0;
            border-left: 1px dashed $color-line;
        }
        &:nth-of-type(n+3):before {
            content: '';
            display: block;
            position: absolute;
            top: 0; left: 0; right: 0;
            border-top: 1px dashed $color-line;
        }
    }

    @media all and (min-width: 961px) {
        width: 25%;

        &:nth-of-type(n+2):before {
            content: '';
            display: block;
            position: absolute;
            top: 0; bottom: 0; left: 0;
            border-left: 1px dashed $color-line;
        }
    }
}
